import React from 'react';
import KnaekPrimaryButton from "../AppComponents/KnaekPrimaryButton";
import {IoIosArrowDropdown, IoIosArrowDropup} from "react-icons/io";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';

export default function AccordionItemKnaekVacancy({
                                                      title, vacancyContent, button, buttonLink, header1,
                                                      isExpanded, toggleCollapse, idx
                                                  }) {
    const handleLinkClick = useHandleLinkClick();

    return (
        <div key={idx} className="vacancy-card-style px-lg-2 px-md-2 px-1">
            <div className="card-header border-0 px-lg-2 px-md-2 px-0 d-flex justify-content-between bg-white"
                 onClick={() => toggleCollapse(idx)}>
                <section className={"d-flex justify-content-center align-items-center"}>
                    <a className={` d-none d-md-block module-image p-lg-4 p-md-4 d-inline-block rounded-circle text-center mr-3 ${isExpanded === idx && "module-image-expanded d-none d-md-block"}`}>
                        <svg width="40" height="40" viewBox="0 0 32 32"
                             className="d-none d-md-block">
                            <path
                                d="M28.22,24.62V19.75H17V16.94h5.62V14.13a6.56,6.56,0,1,0-13.12,0v2.81h5.62v2.81H3.78v4.87a3.75,3.75,0,1,0,1.88,0v-3h9.43v3a3.75,3.75,0,1,0,1.88,0v-3h9.37v3a3.75,3.75,0,1,0,1.88,0ZM6.59,28.25a1.88,1.88,0,1,1-1.87-1.87A1.88,1.88,0,0,1,6.59,28.25Zm11.32,0A1.88,1.88,0,1,1,16,26.38,1.88,1.88,0,0,1,17.91,28.25ZM11.34,15.06v-.93a4.71,4.71,0,0,1,3.75-4.6v3.66H17V9.53a4.7,4.7,0,0,1,3.75,4.6v.93ZM27.28,30.13a1.88,1.88,0,1,1,1.88-1.88A1.88,1.88,0,0,1,27.28,30.13ZM19.78,3.81a3.75,3.75,0,1,0-7.5,0,3.75,3.75,0,1,0,7.5,0ZM16,5.69a1.88,1.88,0,0,1-1.87-1.88,1.88,1.88,0,1,1,3.75,0A1.88,1.88,0,0,1,16,5.69Z"></path>
                        </svg>

                    </a>
                    <h2 className={"d-none d-lg-block"}>{title}</h2>
                    <h5 className={"d-block d-lg-none"}>{title}</h5>

                </section>
                <section className="d-flex justify-content-center align-items-center">
                    {isExpanded === idx ? <IoIosArrowDropup className="ml-5 icon-arrow-colored"/> :
                        <IoIosArrowDropdown className="ml-5 icon-arrow"/>}
                </section>
            </div>
            <div className={`collapse ${isExpanded === idx && "show"}`}>
                <div className="card-body">
                    <h2 className={"d-none d-lg-block mt-2"}>{header1}</h2>
                    <h4 className={"d-block d-lg-none mt-2"}>{header1}</h4>
                </div>
                {vacancyContent.map(({header, section}, id) => (
                        <div className="card-body mt-0 pt-0" key={id}>
                            <h3 className={"d-none d-lg-block "}>{header}</h3>
                            <h5 className={"d-block d-lg-none "}>{header}</h5>
                            <p>{section}</p>
                        </div>
                ))}
                {/*<div className="row ">*/}
                <div className="col-12 mt-5">
                    <div className="col-6 col-md-12 col-sm-12 justify-content-center align-items-center d-flex">
                        <KnaekPrimaryButton
                            className={" mt-2 mb-4"}
                            title={button}
                            onClick={()=> handleLinkClick(buttonLink)}
                        />
                    </div>

                </div>
                {/*</div>*/}

            </div>
        </div>
    );
}
