import React, {Component} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";






export default function TermsAndConditionsContent() {

    const initStrings =
        {

            header: stringsTranslate(appWords.general_conditions_knaek),
            "termsdata": [

                {
                    "id": 1,
                    "title": stringsTranslate(appWords.terms_and_conditions),
                    "terms_content": [
                        {

                            "section": stringsTranslate(appWords.in_these_terms_and_conditions_we_have_described_the_rules_that_apply_to_each_other)

                        },
                        {

                            "section": stringsTranslate(appWords.alert_privacy_policy)

                        },
                        {
                            "section": stringsTranslate(appWords.we_have_done_our_best_to_describe_the_terms_and_conditions_as_clearly_as_possible)
                        },
                        {
                            "section": stringsTranslate(appWords.do_you_have_a_tip__question_or_comment__please_fee)
                        },
                        {
                            "section": "info@knaek.nl | +31 (0) 20 - 716 57 71"
                        },
                        {
                            "section": "--"
                        },
                        {
                            "section": stringsTranslate(appWords.these_general_terms_and_conditions_are_effective_a),
                        }
                    ],

                },
                {
                    "id": 2,
                    "title": stringsTranslate(appWords.article_1_definitions),
                    "terms_content": [
                        {


                            "section": stringsTranslate(appWords.in_these_general_terms_and_conditions_we_mean_by),

                        },
                        {
                            "header": stringsTranslate(appWords.knaek_we),
                            "section": stringsTranslate(appWords.the_private_company_knaek_b_v_located_at_admirale)
                        },
                        {
                            "header": stringsTranslate(appWords.member),
                            "section":
                                stringsTranslate(appWords.any_natural_person_who_represents_a)

                        },
                        {
                            "header":
                                stringsTranslate(appWords.user),
                            "section":
                                stringsTranslate(appWords.someone_who_uses_the_knaekapp_via_a_one_time_activ)

                        },
                        {
                            "header":
                                stringsTranslate(appWords.you),
                            "section":
                                stringsTranslate(appWords.the_member__user_or_visitor_of_our)

                        },
                        {
                            "header":
                                stringsTranslate(appWords.knaekapp)
                                ,
                            "section":
                                stringsTranslate(appWords.knaek_s_digital_discount_pass)
                        },
                        {
                            "header":
                                stringsTranslate(appWords.knaek_id)
                                ,
                            "section":
                                stringsTranslate(appWords.the_activation_code_issued_by_knaek_that_gives_acc)

                        },
                        {
                            "header":
                                stringsTranslate(appWords.acceptable)
                                ,
                            "section":
                                stringsTranslate(appWords.the_company_or_institution_that_accepts_the_knaeka)

                        },
                        {
                            "header":
                                stringsTranslate(appWords.membership_fee)
                             ,
                            "section":
                                stringsTranslate(appWords.the_annual_fee_you_pay)
                        },
                        {
                            "header":
                                stringsTranslate(appWords.agreement)
                                ,
                            "section":
                                stringsTranslate(appWords.the_agreement_between_the_member)
                        },
                        {
                            "header":
                                stringsTranslate(appWords.college_year)
                                ,
                            "section":
                                stringsTranslate(appWords.the_academic_year_starts_on_august_1_of_the_relev)

                        },
                        {
                            "header":
                                stringsTranslate(appWords.knaek_cashback)
                                ,
                            "section":
                                stringsTranslate(appWords.the_service_provided_by_our_partner_the_affiliate_)

                        },
                        {
                            "header":
                                stringsTranslate(appWords.knaek_platform)
                            ,
                            "section":
                                stringsTranslate(appWords.the_service_platform)

                        },
                    ],


                },
                {
                    "id": 3,
                    "title":
                        stringsTranslate(appWords.article_2_applicability)
                        ,
                    "terms_content": [
                        {

                            "section":
                                stringsTranslate(appWords.these_general_terms_and_conditions_apply__unless_o)

                        },
                    ],
                },
                {
                    "id": 4,
                    "title":
                        stringsTranslate(appWords.article_3_membership)
                        ,
                    "terms_content": [
                        {
                            "section":
                                stringsTranslate(appWords.as_a_valued_member_of_knaek__you_will_receive_acce)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.by_selling_membership__we_are_able_to_offer_the_ve)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.you_can_become_a_member_of_knaek_in_the_following_)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.you_can_only_become_a_member_if_you_are_a_student)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.membership_starts_immediately_after_registration)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.when_you_take_out_a_membership_at_knaek)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.we_charge_a_membership_fee_for_membership)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.you_pay_your_membership_fee_by_direct_debit_or_cre)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.you_can_cancel_your_subscription_with_knaek_subjec)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.membership_can_be_canceled_at_any_time9)

                        },
                        {
                            "section":

                                stringsTranslate(appWords.membership_can_be_canceled_at_any_time)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.you_will_always_have_to_cancel_the_membership_your)

                        },

                    ],


                },
                {
                    "id": 5,
                    "title":
                        stringsTranslate(appWords.article_4_payment_of_the)
                       ,
                    "terms_content": [
                        {

                            "section":
                                stringsTranslate(appWords.the_payment_of_your_membership_fee_contributes_to)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.as_a_member_of_knaek_you_are_a_financial_contribut)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.the_membership_fee_is_debited_at_the_end_of_each)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.if_you_cancel_prematurely__you_will_receive_an_ema)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.if_we_are_unable_to_debit_the_membership_fee_from_)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.if_you_do_not_pay_a_payment_request_on_time__we_wi)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.in_the_exceptional_case_that_your_account_number_i)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.the_reversal_or_blocking_of_a_direct_debit_by)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.although_we_rarely_increase_the_membership_fee)

                        },
                    ],

                },
                {
                    "id": 6,
                    "title":
                        stringsTranslate(appWords.article_5_right_of_withdrawal)
                        ,
                    "terms_content": [
                        {

                            "section":
                                stringsTranslate(appWords.if_you_take_out_a_membership_with_knaek_via_our_di)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.the_cooling_off_period_starts_on_the_day_after_you)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.if_you_use_the_right_of_withdrawal)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.the_right_of_withdrawal_is_not_valid_if_you_have_b)

                        },
                    ],

                },
                {
                    "id": 7,
                    "title":
                        stringsTranslate(appWords.article_6_the_knaek_id)
                        ,
                    "terms_content": [
                        {

                            "section":
                                stringsTranslate(appWords.the_knaek_id_is_an_activation_code_that_can_be_act)

                        },
                        {
                            "header":

                                stringsTranslate(appWords.one_time_activation_code)
                               ,
                            "section":
                                stringsTranslate(appWords.these_are_the_knaek_ids_that_we_hand_out_to_partne)

                        },
                        {
                            "header":
                                stringsTranslate(appWords.membership_with_continuous_activation_code)
                               ,
                            "section":
                                stringsTranslate(appWords.this_is_a_personal_knaek_id_that_you_receive_as_a_)


                        },

                    ],


                },
                {
                    "id": 8,
                    "title":
                        stringsTranslate(appWords.article_7_cancellation)
                        ,
                    "terms_content": [
                        {

                            "section":
                                stringsTranslate(appWords.have_you_graduated_or_do_you_no_longer_want_to_be_)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.are_you_canceling_for_another_reason_we_are_always)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.membership_can_be_canceled_at_any_time_1)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.please_note_that_you_will_always_receive_a_cancell)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.if_you_have_cancelled__your_knaek_id_will_expire_a)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.as_a_member_you_retain_the_obligation_to_terminate)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.in_case_you_still_have_a_membership_fee_open)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.knaek_is_free_to_do_so_without_stating_reasons)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.knaek_is_free_to_do_so_without_stating_reasons7)

                        },
                    ],

                },
                {
                    "id": 9,
                    "title":
                    stringsTranslate(appWords.article_8_obligations_and_indemnifications)
                        ,
                    "terms_content": [
                        {

                            "section":
                            stringsTranslate(appWords.we_inform_our_members__among_other_things__through)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.we_make_every_effort_to_update_this_information_re)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.the_user_of_the_knaekapp_must_ask_for_a_knaek_disc)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.despite_the_fact_that_we_make_every_effort_to_comm)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.despite_the_fact_that_we_make_every_effort_to_comm4)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.despite_the_fact_that_we_make_every_effort_to_comm5)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.despite_the_fact_that_we_make_every_effort_to_comm6)

                        },,
                        {
                            "section":
                                stringsTranslate(appWords.despite_the_fact_that_we_make_every_effort_to_comm7)

                        },

                    ],

                },
                {
                    "id": 10,
                    "title":
                    stringsTranslate(appWords.article_9_obligations_of_members_or_users)
                    ,
                    "terms_content": [
                        {

                            "section":
                            stringsTranslate(appWords.as_a_member_user__you_submit_your_knaek_id_yoursel)

                        },
                    ],

                },
                {
                    "id": 11,
                    "title":
                    stringsTranslate(appWords.article_10_annual_contribution)
                        ,
                    "terms_content": [
                        {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_2)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_3)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_4)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_5)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_6)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_7)

                        },
                     {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_8)

                        },
                     {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_9)

                        },
                     {

                            "section":
                            stringsTranslate(appWords.the_annual_contribution_for_membership_is_25_inclu_10)

                        },
                    ],

                },
                {
                    "id": 12,
                    "title":
                    stringsTranslate(appWords.article_11_personal_data)
                        ,
                    "terms_content": [
                        {

                            "section":
                            stringsTranslate(appWords.we_process_your_contact_details)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.as_a_member_or_user_you_have_the_right_to_inspect_)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.bank_details_of_members_are_shared_with_our_admini)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.the_knaekapp_is_intended_to_benefit_the_member_or_)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.phone_calls_with_the_member_or_user_can_be)

                        },
                    ],

                },
                {
                    "id": 13,
                    "title":
                    stringsTranslate(appWords.article_12_liability)
                        ,
                    "terms_content": [
                        {

                            "section":
                            stringsTranslate(appWords.knaek_is_not_liable_for_any_damage__by_whatever_na)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.knaek_is_not_liable_for_any_damage__by_whatever_na_2)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.knaek_is_not_liable_for_any_damage__by_whatever_na_3)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.knaek_is_not_liable_for_any_damage__by_whatever_na_4)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.knaek_is_not_liable_for_any_damage__by_whatever_na_5)

                        },
                        {

                            "section":
                            stringsTranslate(appWords.knaek_is_not_liable_for_any_damage__by_whatever_na_6)

                        },
                    ],

                },
                {
                    "id": 14,
                    "title":
                    stringsTranslate(appWords.article_13_force_majeure)
                        ,
                    "terms_content": [
                        {

                            "section":
                            stringsTranslate(appWords.knaek_is_not_obliged_to_fulfill_any_obligation)

                        },
                    ],

                },
                {
                    "id": 15,
                    "title":
                    stringsTranslate(appWords.articles_14)
                        ,
                    "terms_content": [
                        {

                            "section":
                            stringsTranslate(appWords.article_14_correspondence_and_changes_of_address)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.correspondence_related_to_knaek_such_as_correspon)

                        },
                        {
                            "section":
                            stringsTranslate(appWords.in_case_of_a_change_of_address__the_member_or_user)

                        },
                    ],
                },
                {
                    "id": 16,
                    "title":
                    stringsTranslate(appWords.articles_15)
                        ,
                    "terms_content": [

                        {

                            "section":
                            stringsTranslate(appWords.despite_all_the_care_we_devote_to_our_services__it)

                        },
                        {
                            "section":
                                stringsTranslate(appWords.despite_all_the_care_we_devote_to_our_services_2_it)

                        },
                    ],
                },
                {
                    "id": 17,
                    "title":
                    stringsTranslate(appWords.articles_16)
                        ,
                    "terms_content": [

                        {

                            "section":
                            stringsTranslate(appWords.article_16_amendment_of_the_general_terms_and_con)

                        },
                    ],
                },
                {
                    "id": 18,
                    "title":
                    stringsTranslate(appWords.articles_17)
                        ,
                    "terms_content": [
                        {
                            "section":
                            stringsTranslate(appWords.we_are_at_all_times_entitled_to_these_terms_and_co)

                        },
                    ],
                },


            ],
        }


    return (
        <section className="section faq-area ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="terms-and-conditions align-items-center mb-5 justify-content-center">
                            <h1>{initStrings.header}</h1>
                        </div>
                        {/* FAQ Content */}
                        <div className="faq-content">
                            {/* sApp Accordion */}
                            <div className="accordion radius-10" id="sApp-accordion">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-10 col-lg-12">
                                        {/* Single Accordion Item */}
                                        {initStrings.termsdata.map((item, idx) => {
                                            return (
                                                <div key={`flth_${idx}`}
                                                     className=" radius-10 terms-card-style ">
                                                    {/* Card Header */}
                                                    <div className="card-header border-0 radius-10 bg-white"
                                                         data-toggle="collapse" data-target={"#collapseOne" + idx}>
                                                        <h3 className={"d-none d-lg-block mt-2"}>{item.title}</h3>
                                                        <h5 className={"d-block d-lg-none mt-2"}>{item.title}</h5>
                                                    </div>
                                                    <div id={"collapseOne" + idx} className="collapse">
                                                        {item.terms_content.map((item2, id) => {
                                                            return (
                                                                <div key={`flth_${id}`}>

                                                                    {/* Card Body */}
                                                                    <div className="card-body">
                                                                        <h5>{item2.header}</h5>
                                                                        <p dangerouslySetInnerHTML={{__html:item2.section }}></p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


