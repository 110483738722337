import React, {useEffect, useState} from 'react';
import BannerSectionStoreDiscounts
    from "../../components/AppSections/StoreDiscountsPageSections/BannerSectionStoreDiscounts";
import StoreDiscountsPart from "../../components/AppSections/StoreDiscountsPageSections/StoreDiscountsPart";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";

export default function StoreDiscountsPage() {
    const local_discounts_CM = stringsTranslate(appWords.local_discounts_CM);
    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {local_discounts_CM}</title>
            </Helmet>
            <div className="main">
                <BannerSectionStoreDiscounts/>
                <StoreDiscountsPart/>
            </div>
        </div>
    );
}
