import React from 'react';
import FooterSection from '../components/FooterSection/Footer';
import BannerSectionHome from "../components/AppSections/HomepageSections/BannerSectionHome";
import OnlineSectionHome from "../components/AppSections/HomepageSections/OnlineSectionHome";
import HowItWorksSectionHome from "../components/AppSections/HomepageSections/HowItWorksSectionHome";
import HowItWorkCashback from "../components/AppSections/HomepageSections/HowItWorkCashback";
import RequestYourKnaekID from "../components/AppSections/HomepageSections/RequestYourKnaekID";
import {Helmet} from "react-helmet";
import {appWords} from "../config/Constants";
import {stringsTranslate} from "../locales/CustomTranslater";

export default function Home() {
    const home_page = stringsTranslate(appWords.home_page);
    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {home_page}</title>
            </Helmet>
            <div className="main">
                <BannerSectionHome/>
                <OnlineSectionHome/>
                <HowItWorksSectionHome/>
                <HowItWorkCashback id={"how-it-works-cb"}/>
                <RequestYourKnaekID />
                <FooterSection/>
            </div>
        </div>
    );
}
