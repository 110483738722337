import React from 'react';
import {hasAValue} from "../../../uitls/SharedFunctions";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import FavoriteDiscountCard from "../../AppComponents/FavoriteDiscountCard";
import SnipTechOnlineDiscountCard from "../../AppComponents/SniptechOnlineDiscountCard";
import ImageComponent from "../../AppComponents/ImageComponent";


export default function FavoritesProfileSection({companyFavorites, snipTechFavorites}) {

    const initStrings = {
        header: stringsTranslate(appWords.favorites),
        localStores: stringsTranslate(appWords.store_discounts_cl),
        companies: stringsTranslate(appWords.companies).toUpperCase(),
        onlineStores: stringsTranslate(appWords.online_discounts_cl),
        no_favorites: stringsTranslate(appWords.no_favorites),
    }

    return (
        <div className={"mt-4"}>
            <h1 className="ml-4">{initStrings.header}</h1>
            <div className={"divider-horizontal ml-4"}/>

            <div className="ml-4">
                <h3>
                    {initStrings.companies}
                </h3>
            </div>
            <section className="row justify-content-start ml-4">
                {companyFavorites.map((company, i) => {
                    if (hasAValue(company)) {
                        return (
                            <div className="store-discount-card" key={i}>
                                <FavoriteDiscountCard item={company} isFavorite={true}/>
                            </div>
                        )
                    }
                })}
                {companyFavorites.length === 0 &&
                    <div className="row justify-content-start">
                        <div className="col-12">
                            <div className="col-12 text-center">
                                <ImageComponent src={"/assets/images/no-favorites.png"}
                                                className="homepage-online-section-online-card-background-fav" alt=""/>
                                <h4 className="mt-3 no-fav-text text-knaek-primary">{initStrings.no_favorites}</h4>
                            </div>
                        </div>
                    </div>
                }
            </section>
            <div className="mt-5 ml-4">
                <h3>
                    {initStrings.onlineStores}
                </h3>
            </div>
            <section className={"row justify-content-start ml-4"}>
                {snipTechFavorites.map(item => {
                        return (<div className="store-discount-card" key={item.id}>
                            <SnipTechOnlineDiscountCard item={item} hideFavorite={true}/>
                        </div>)
                    }
                )}
                {snipTechFavorites.length === 0 &&
                    <div className="row justify-content-start">
                        <div className="col-12">
                            <div className="col-12 text-center">
                                <ImageComponent src={"/assets/images/no-favorites.png"}
                                                className="homepage-online-section-online-card-background-fav" alt=""/>
                                <h4 className="mt-3 no-fav-text text-knaek-primary">{initStrings.no_favorites}</h4>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </div>
    );
}
