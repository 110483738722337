import React, {useState} from 'react';
import UnsubscribeInfoSection from "../../components/AppSections/Unsubscribe/UnsubscribeInfoSection";
import UnsubscribeFormSection from "../../components/AppSections/Unsubscribe/CancellingMembershipSections";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";

export default function Unsubscribe() {
    const unsubscribe = stringsTranslate(appWords.unsubscribe)
    const [cancellationInfoShow, setCancellationInfoShow] = useState(true)
    return (
        <div className="homepage-4 pt-4 mt-5">
            <div className="main">
                <Helmet>
                    <title>Knaek - {unsubscribe}</title>
                    <meta property="description" content={"Net als jij houden we niet van dingen waar je moeilijk vanaf komt, daarom hebben we het afmelden zo eenvoudig mogelijk gemaakt"} />
                    <meta property="keywords" content={"Opzeggen"} />

                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content="Knaek-opzeggen" />
                    <meta property="og:description" content={"Net als jij houden we niet van dingen waar je moeilijk vanaf komt, daarom hebben we het afmelden zo eenvoudig mogelijk gemaakt"}/>
                    <meta property="og:keywords" content={"Opzeggen"}/>
                    <meta property="og:type" content="website" />

                </Helmet>
                {cancellationInfoShow && <UnsubscribeInfoSection setCancellationInfoShow={()=> setCancellationInfoShow(!cancellationInfoShow)}/>}
                {!cancellationInfoShow && <UnsubscribeFormSection />}
            </div>
        </div>
    );
}
