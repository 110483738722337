import React from 'react';

export default function KnaekSecondaryButton({className, title, ...props}) {

    return (
        <button {...props}  className={` btn btn-secondary radius-50 shadow p-4 px-5 ${className}`}>
            {title}
        </button>
    );
}
