import React, {forwardRef} from "react";

const ReviewSubmitButton = forwardRef(({ className, title, ...props }, ref) => {
    return(
        <button ref={ref} className={`btn review-submit radius-50 shadow p-4 px-5 ${className}`} {...props}>
            {title}
        </button>
    );
});


export default ReviewSubmitButton;