import React, {useRef, useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import { useHandleLinkClick } from '../../../hooks/useHandleLinkClick';
import Slider from "react-slick";

export default function PricingCard() {
    const handleLinkClick = useHandleLinkClick();

    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style}}
            onClick={onClick}
          >
            <i className='fa fa-chevron-right'></i>
        </div>
        );
      }
      
    function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style}}
            onClick={onClick}
          >
            <i className='fa fa-chevron-left'></i>
        </div>
        );
      }
    const slickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };  
    const initStrings = {
        heading3: stringsTranslate(appWords.prices),
        subheading3: stringsTranslate(appWords.at_knaek_you_dont_have_to_spend_a_fortune_to_get_promoted),
        playImg3: "/assets/images/studentkorting/kossten1.png",
        contactUs: stringsTranslate(appWords.contact_us),
        pricingBasic: stringsTranslate(appWords.pricing_card_basic),
        pricingStandard: stringsTranslate(appWords.pricing_card_standard),
        pricingPremium: stringsTranslate(appWords.pricing_card_premium),
        pricingPayAsYouGo: stringsTranslate(appWords.pricing_card_pay_as_you_go),
        pricingEuroPerMonth: stringsTranslate(appWords.pricing_euro_per_month),
        pricingEuroPerCoupon: stringsTranslate(appWords.pricing_euro_per_coupon),
        pricingMaximumNumberOfBranches: stringsTranslate(appWords.pricing_maximum_number_of_branches),
        pricingMaximumNumberOfCoupons: stringsTranslate(appWords.pricing_maximum_number_of_coupons),
        pricingMaximumNumberOfCouponsPerYear: stringsTranslate(appWords.pricing_maximum_number_of_coupons_per_year),
        pricingAnnualAdvertisingCredit: stringsTranslate(appWords.pricing_annual_advertising_credit),
        pricingUnlimited: stringsTranslate(appWords.pricing_unlimited),
        pricingAtJoppboard: stringsTranslate(appWords.pricing_at_joppboard),
    }

    const textStyles = {
        fontWeight: '600',
        fontSize: '0.8rem',
    }
    const pricingCards = [
        {
            title: initStrings.pricingBasic,
            price: '10,00',
            text1: initStrings.pricingEuroPerMonth,
            text2: `${initStrings.pricingMaximumNumberOfBranches}:`,
            text3: 2,
            text4: `${initStrings.pricingMaximumNumberOfCouponsPerYear}:`,
            text5: 200,
            text6: `${initStrings.pricingAnnualAdvertisingCredit}:`,
            text7: initStrings.pricingAtJoppboard,
            text8: 249,
        },   
        {
            title: initStrings.pricingStandard,
            price: '50,00',
            text1: initStrings.pricingEuroPerMonth,
            text2: `${initStrings.pricingMaximumNumberOfBranches}:`,
            text3: 10,
            text4: `${initStrings.pricingMaximumNumberOfCouponsPerYear}:`,
            text5: 600,
            text6: `${initStrings.pricingAnnualAdvertisingCredit}:`,
            text7: initStrings.pricingAtJoppboard,
            text8: 249,
            isHighlighted: true,
        },   
        {
            title: initStrings.pricingPremium,
            price: '190,00',
            text1: initStrings.pricingEuroPerMonth,
            text2: `${initStrings.pricingMaximumNumberOfBranches}:`,
            text3: initStrings.pricingUnlimited,
            text4: `${initStrings.pricingMaximumNumberOfCouponsPerYear}:`,
            text5: initStrings.pricingUnlimited,
            text6: `${initStrings.pricingAnnualAdvertisingCredit}:`,
            text7: initStrings.pricingAtJoppboard,
            text8: 249,
        },   
        {
            title: initStrings.pricingPayAsYouGo,
            price: '1,50',
            text1: initStrings.pricingEuroPerCoupon,
            text2: `${initStrings.pricingMaximumNumberOfBranches}:`,
            text3: initStrings.pricingUnlimited,
            text4: `${initStrings.pricingMaximumNumberOfCoupons}:`,
            text5: 50,
        },   
    ]

    return (
        
        <section className="section mt-4 pt-2 mb-0 d-flex justify-content-center">
            <div className="col-12 col-md-11">
                {/* Pricing section */}
                <div className="row mt-5 mb-5">
                    <h2 className={"m-3 p-3 mt-5 mx-auto"}>{initStrings.heading3}</h2>

                    {/* Cards */}
                    <div className="col-12">
                        <div className="row">
                            
                              {pricingCards.map((pricing, idx)=>
                                  <div key={`pricing-keys-${idx}`} className="col-12 col-sm-6 col-md-6 col-lg-3 mb-5 mb-xl-0">
                                      
                                      <div className="card border-0 py-2 shadow h-100" style={pricing?.isHighlighted? {borderRadius:'16px', backgroundColor:'#faeaf4'} : {borderRadius:'16px'}}>
                                          <a className={"cursor-pointer"} onClick={()=> handleLinkClick("/contact#contact-form")}>
                                              <div className="card-body text-center" style={{minHeight: "525px"}}>
                                                  <h3 className="mb-5" style={{fontSize: '1.5rem'}}>{pricing.title}</h3>
                                                  
                                                  <h1 className="mb-4" style={{fontSize: '3rem', fontWeight: '600'}}>{pricing.price}</h1>
                                                  
                                                  <p style={textStyles}>{pricing.text1}</p>

                                                  <KnaekPrimaryButton
                                                      title={initStrings.contactUs}
                                                      className={"my-5 px-2"}
                                                  />
                                                  
                                                  <p style={textStyles}>{pricing.text2}</p>
                                                  <p className='mb-3' style={{color:'#D44F9E', ...textStyles}}>{pricing.text3}</p>

                                                  <p style={textStyles}>{pricing.text4}</p>
                                                  <p className='mb-3' style={{color:'#D44F9E', ...textStyles}}>{pricing.text5}</p>

                                                  <p style={textStyles}>{pricing.text6}</p>
                                                  {pricing.text7 && <p style={{color:'#D44F9E', ...textStyles}}>{pricing.text7} <span>&euro;{pricing.text8}</span></p>}
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}