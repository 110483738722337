import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import ListItemsJobs from "../../AppCustomizedComponent/ListItemsJobs";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import FilterCityJobs from "../../AppCustomizedComponent/FilterCityJobs";
import {hasAValue} from "../../../uitls/SharedFunctions";



export default function JobsPart() {

    const initStrings = {
    section1heading: stringsTranslate(appWords.jopp),
    section1CounterText: stringsTranslate(appWords.results),
    sectionSearchHeading: stringsTranslate(appWords.results),
    sectionFilterHeading: stringsTranslate(appWords.college_town),
    searchPlaceholder: stringsTranslate(appWords.find_a_job),
}
    const jobsRS = useSelector(state => state.jobsRS);

    const [selectedFilterCity, setSelectedFilterCity] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [jobs, setJobs] = useState(jobsRS)

    useEffect(() => {
        let filteredData = jobsRS.filter(item => (item.jobTitle.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
                item.company.toString().toLowerCase().includes(searchText.toString().toLowerCase())
            || item.tags.map(tag => tag.name).toString().toLowerCase().includes(searchText.toString().toLowerCase()))
        && ((hasAValue(selectedFilterCity)) ? JSON.stringify(item.location).includes(selectedFilterCity.name): true))
        setJobs(filteredData)

    }, [searchText, selectedFilterCity])

    return (
        <section className="section align-items-start justify-content-start mt-5">
            <div className="container-fluid mb-5">
                <div className="row">

                    {/*Element 1*/}
                    <div className="col-lg-2 col-12 text-left border-top border-right d-flex align-items-center">
                        <p className="text-primary">{jobs.length + " " + initStrings.section1CounterText}</p>
                    </div>

                    {/*Element Search balk*/}
                    <div className="col-lg-10 col-12 text-left border-top d-flex align-items-center">
                        <span className="input-group-text w-100 h-100 border-0 bg-white">
                            <i className="fa fa-search text-primary"/>
                            <input type="text" className="form-control search-input-store-discounts h6 h-100 mt-2"
                                   placeholder={initStrings.searchPlaceholder}
                                   onChange={(val) => setSearchText(val.target.value)}/>
                        </span>
                    </div>

                    <div className="col-lg-2 col-12 text-left border-top border-right border-bottom pb-3 pl-3 pr-3">

                        <FilterCityJobs initStrings={initStrings}
                                                  selectedFilterCity={selectedFilterCity}
                                                  setSelectedFilterCity={(val) => setSelectedFilterCity(val)}/>
                    </div>

                    {/*Element List items*/}
                    <ListItemsJobs jobsRS={jobs}/>
                </div>
            </div>
        </section>
    );
}
