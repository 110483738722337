import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {persistor, reduxStore} from './redux/reduxStore';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from "react-redux";


ReactDOM.render(
    <Provider store={reduxStore}>
        <PersistGate loading={null} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);
