import React from 'react';
import FooterSection from '../../components/FooterSection/Footer';
import PartnersSection from "../../components/AppSections/PartnersOfKnaek/PartnersSection";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";

export default function PartnersVanKnaek() {

    const our_partners_cl = stringsTranslate(appWords.our_partners_cl)
    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {our_partners_cl}</title>
            </Helmet>
            <div className="main">
                <PartnersSection />
                <FooterSection/>
            </div>
        </div>
    );
}
