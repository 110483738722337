import React from 'react';
import BannerSectionJobs from "../../components/AppSections/JobsPageSections/BannerSectionJobs";
import JobsPart from "../../components/AppSections/JobsPageSections/JobsPart";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";

export default function JobsPage() {
    const student_jobs_sl = stringsTranslate(appWords.student_jobs_sl)
    return (
        <div className="pt-5 mt-5">
            <Helmet>
                <title>Knaek - {student_jobs_sl}</title>
            </Helmet>
                <BannerSectionJobs/>
                <JobsPart/>
        </div>
    );
}
