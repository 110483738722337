import React from 'react';
import {appUrls} from "../../config/Constants";
import ImageComponent from "./ImageComponent";
import {useParams} from "react-router-dom";
import {hasAValue} from "../../uitls/SharedFunctions";
import {useSelector} from "react-redux";
import {FiHeart} from "react-icons/fi";
import {FaHeart} from "react-icons/fa";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';

export default function StoreDiscountCard({item, isFavorite}) {
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    let contentLanguage = appLanguageRS === "en"? "en": "nl"
    const handleLinkClick = useHandleLinkClick();

    let language = appLanguageRS
    const {city} = useParams();
    return (
        <div className={"card radius-15 border-0 store-discount-card w-100 cursor-pointer shadow"} onClick={() => goToInfo()}>
            <ImageComponent src={appUrls.image_url + item.imageId} className="store-discount-card-background" alt={`studentenkorting-${item.localization[contentLanguage].title}`}/>

            <div className="card-body row store-discount-card-body">
                <div className={"col-12 d-flex justify-content-center"}>
                    <ImageComponent src={appUrls.image_url + item.iconId}
                                    className="bg-white radius-15 store-discount-card-logo shadow"
                                    alt="" height={98} width={98}/>

                </div>
                <div className={"col-12 text-center"}>
                    <h4 className="w-100 mt-2 text-knaek-title-new">{item.localization[contentLanguage].subtitle}</h4>
                    <h6 className="w-100 text-knaek-regular">{item.localization[contentLanguage].title}</h6>
                </div>
            </div>

        </div>
    );


    function goToInfo() {
        let targetCity = hasAValue(city) ? city : item.cityNames[0];
        let targetName = item.companyName
        let targetId = item.actionId;

        // Construct the URL
        const targetUrl = `/studentenkorting/${encodeURIComponent(targetCity)}/${targetName}/${encodeURIComponent(targetId)}`;

        // Navigate to the new URL
        handleLinkClick(targetUrl);
    }
}
