import React, {Component} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";





export default function PrivacyContent() {

    const initStrings = {
        titlePage: stringsTranslate(appWords.privacy_policy),
        knaek_promotions_located_at_admiralengracht: stringsTranslate(appWords.knaek_promotions_located_at_admiralengracht),
        emailLinkAction: "mailto:info@knaek.nl" ,
        email_address: "info@knaek.nl",
        contact_details: stringsTranslate(appWords.contact_details),
        linkToWebsite: "https://www.knaek.nl",
        addressPart1: "Admiralengracht 60h",
        addressPart2: "1057GB Amsterdam",
        phoneNumberLabel: "+31 2 0716 5771",
        phoneNumberAction: "tel:+31207165771",
        Incassant: "Incassant ID: NL77ZZZ549539790000",
        personal_data_that_we_process: stringsTranslate(appWords.personal_data_that_we_process),
        knaek_promotions_processes_your_personal_data: stringsTranslate(appWords.knaek_promotions_processes_your_personal_data),
        below_you_will_find_an_overview_of_the_personal_da: stringsTranslate(appWords.below_you_will_find_an_overview_of_the_personal_da),


        first_and_last_name: stringsTranslate(appWords.first_and_last_name),
        sex: stringsTranslate(appWords.sex),
        date_of_birth: stringsTranslate(appWords.date_of_birth),
        birthplace: stringsTranslate(appWords.birthplace),
        liText4: stringsTranslate(appWords.address_details),
        telephone_number: stringsTranslate(appWords.telephone_number),
        personal_email_address: stringsTranslate(appWords.personal_email_address),
        student_email_address: stringsTranslate(appWords.student_email_address),
        student_email_address_study: stringsTranslate(appWords.student_email_address_study),
        student_email_address_study_bank_number:  stringsTranslate(appWords.student_email_address_study_bank_number),
        bank_details_bank_account_number_and_bank_account:  stringsTranslate(appWords.bank_details_bank_account_number_and_bank_account),

        special_and_or_sensitive_personal_data_that_we_pro: stringsTranslate(appWords.special_and_or_sensitive_personal_data_that_we_pro),
        our_website_and_or_service_does_not_intend_to_coll: stringsTranslate(appWords.our_website_and_or_service_does_not_intend_to_coll),
        for_what_purpose_and_on_what_basis_we_process_pers: stringsTranslate(appWords.for_what_purpose_and_on_what_basis_we_process_pers),
        knaek_promotions_processes_your_personal_data2: stringsTranslate(appWords.knaek_promotions_processes_your_personal_data2),
        handling_your_payment: stringsTranslate(appWords.handling_your_payment),
        sending_our_news_letter_to_keep_you_informed_abo: stringsTranslate(appWords.sending_our_news_letter_to_keep_you_informed_abo),
        to_be_able_to_call_or_e_mail_you_if_this_is_necess:  stringsTranslate(appWords.to_be_able_to_call_or_e_mail_you_if_this_is_necess),
        inform_you_about_changes_to_our_services_and_produ: stringsTranslate(appWords.inform_you_about_changes_to_our_services_and_produ),
        to_offer_you_the_opportunity_to_create_an_account: stringsTranslate(appWords.to_offer_you_the_opportunity_to_create_an_account),
        marketing_purposes_: stringsTranslate(appWords.marketing_purposes_),
        personalized_offers_and_reminders_based_on_profile: stringsTranslate(appWords.personalized_offers_and_reminders_based_on_profile),
        statistical_and_analytical_purposes_to_improve_our: stringsTranslate(appWords.statistical_and_analytical_purposes_to_improve_our),
        statistical_and_analytical_cashback: stringsTranslate(appWords.statistical_and_analytical_cashback),
        automated_decision_making:  stringsTranslate(appWords.automated_decision_making),
        knaek_promotions_does_not_make_decisions_base: stringsTranslate(appWords.knaek_promotions_does_not_make_decisions_base),
        duration_of_retention_of_personal_data: stringsTranslate(appWords.duration_of_retention_of_personal_data),
        knaek_promotions_does_not_store_your_personal:  stringsTranslate(appWords.knaek_promotions_does_not_store_your_personal),
        personal_details:  stringsTranslate(appWords.personal_details),
        when_you_cancel_your_membership__your_account_in_t:  stringsTranslate(appWords.when_you_cancel_your_membership__your_account_in_t),
        address_data:  stringsTranslate(appWords.address_data),
        knaek_stores_the_address_details_for_its_own_finan:  stringsTranslate(appWords.knaek_stores_the_address_details_for_its_own_finan),
        bank_details:  stringsTranslate(appWords.bank_details),
        knaek_stores_the_bank_details_for_its_own_finan:  stringsTranslate(appWords.knaek_stores_the_bank_details_for_its_own_finan),
        email:  stringsTranslate(appWords.email),
        email_addresses:  stringsTranslate(appWords.email_addresses),
        we_will_use_this_information_until_you_withdraw_yo:  stringsTranslate(appWords.we_will_use_this_information_until_you_withdraw_yo),
        other_personal_data_that_you_actively_provide__for:   stringsTranslate(appWords.other_personal_data_that_you_actively_provide__for),
        we_will_use_this_information_until_you_withdraw_yo2:   stringsTranslate(appWords.we_will_use_this_information_until_you_withdraw_yo2),
        sharing_personal_data_with_third_parties:   stringsTranslate(appWords.sharing_personal_data_with_third_parties),
        knaek_promotions_does_not_sell_your_data_to_t:   stringsTranslate(appWords.knaek_promotions_does_not_sell_your_data_to_t),
        cookies__or_similar_techniques__that_we_use: stringsTranslate(appWords.cookies__or_similar_techniques__that_we_use),
        knaek_promotions_does_not_use_cookies_or_simi:  stringsTranslate(appWords.knaek_promotions_does_not_use_cookies_or_simi),
        content16link: "https://download.knaek.com/privacy_policy_amg.php",
        view__modify_or_delete_data:  stringsTranslate(appWords.view__modify_or_delete_data),
        you_have_the_right_to_view__change_or_delete_your: stringsTranslate(appWords.you_have_the_right_to_view__change_or_delete_your_),
        you_can_send_a_request_for_inspection__correction_:  stringsTranslate(appWords.you_can_send_a_request_for_inspection__correction_),
        to_ensure_that_the_request_for_access_has_been_mad: stringsTranslate(appWords.to_ensure_that_the_request_for_access_has_been_mad),
        knaek_promotions_would_also_like_to_point_out: stringsTranslate(appWords.knaek_promotions_would_also_like_to_point_out),
        how_we_protect_personal_data: stringsTranslate(appWords.how_we_protect_personal_data),
        knaek_promotions_takes_the_protection_of_your:  stringsTranslate(appWords.knaek_promotions_takes_the_protection_of_your),
        change_privacy_statement: stringsTranslate(appWords.change_privacy_statement),
        knaek_promotions_is_at_all_times_authorized_t:  stringsTranslate(appWords.knaek_promotions_is_at_all_times_authorized_t),

    }


    return (
        <section className="section faq-area ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="terms-and-conditions align-items-center mb-5 justify-content-center">
                            <h1>{initStrings.titlePage}</h1>
                            <p>{initStrings.knaek_promotions_located_at_admiralengracht} <a className={"text-primary"} href={initStrings.emailLinkAction}>{initStrings.email_address}</a>.</p>
                            <h2 className="mt-3">{initStrings.contact_details}</h2>
                            <a className={"text-primary"} href={initStrings.linkToWebsite}>{initStrings.linkToWebsite}</a>
                            <p className="" >{initStrings.addressPart1}</p>
                            <p className=" ">{initStrings.addressPart2}</p>
                            <a href={initStrings.phoneNumberAction}>{initStrings.phoneNumberLabel}</a> <br/>
                            <a  className={"text-primary"}  href={initStrings.emailLinkAction}>{initStrings.email_address}</a> <br/>
                            <a href={initStrings.link}>{initStrings.content}</a>
                            <p className="">{initStrings.Incassant}</p>
                            <h2 className={"mt-3"}>{initStrings.personal_data_that_we_process}</h2>
                            <p className="mt-2">{initStrings.knaek_promotions_processes_your_personal_data}</p>
                            <h2 className="mt-3">{initStrings.below_you_will_find_an_overview_of_the_personal_da}</h2>
                            <ul className="mt-3">
                                <li className="mt-3 ml-4">{initStrings.first_and_last_name};</li>
                                <li className="mt-3 ml-4">{initStrings.sex};</li>
                                <li className="mt-3 ml-4">{initStrings.date_of_birth};</li>
                                <li className="mt-3 ml-4">{initStrings.birthplace};</li>
                                <li className="mt-3 ml-4">{initStrings.telephone_number};</li>
                                <li className="mt-3 ml-4">{initStrings.personal_email_address};</li>
                                <li className="mt-3 ml-4">{initStrings.student_email_address};</li>
                                <li className="mt-3 ml-4">{initStrings.student_email_address_study};</li>
                                <li className="mt-3 ml-4">{initStrings.student_email_address_study_bank_number}</li>
                                <li className="mt-3 ml-4">{initStrings.bank_details_bank_account_number_and_bank_account}</li>
                            </ul>
                            <h2 className="mt-3">{initStrings.special_and_or_sensitive_personal_data_that_we_pro}</h2>
                            <p className="mt-3" dangerouslySetInnerHTML={{__html: initStrings.our_website_and_or_service_does_not_intend_to_coll }}>
                            </p>
                            <h2 className="mt-3">{initStrings.for_what_purpose_and_on_what_basis_we_process_pers}</h2>
                            <p className="mt-3">{initStrings.knaek_promotions_processes_your_personal_data2}</p>
                            <ul className="mt-3">
                                <li className="mt-3 ml-4">• {initStrings.handling_your_payment};</li>
                                <li className="mt-3 ml-4">• {initStrings.sending_our_news_letter_to_keep_you_informed_abo};</li>
                                <li className="mt-3 ml-4">• {initStrings.to_be_able_to_call_or_e_mail_you_if_this_is_necess};</li>
                                <li className="mt-3 ml-4">• {initStrings.inform_you_about_changes_to_our_services_and_produ};</li>
                                <li className="mt-3 ml-4">• {initStrings.to_offer_you_the_opportunity_to_create_an_account};</li>
                                <li className="mt-3 ml-4">• {initStrings.marketing_purposes_};</li>
                                <li className="mt-3 ml-4">• {initStrings.personalized_offers_and_reminders_based_on_profile};</li>
                                <li className="mt-3 ml-4">• {initStrings.statistical_and_analytical_purposes_to_improve_our};</li>
                                <li className="mt-3 ml-4">• {initStrings.statistical_and_analytical_cashback}</li>
                            </ul>
                            <h2 className="mt-3">{initStrings.automated_decision_making}</h2>
                            <p className="mt-3">{initStrings.knaek_promotions_does_not_make_decisions_base}</p>
                            <h2 className="mt-3">{initStrings.duration_of_retention_of_personal_data}</h2>
                            <p className="mt-3">{initStrings.knaek_promotions_does_not_store_your_personal}</p>
                            <ul>
                                <li className="mt-3 ml-4">
                                    <p className="mt-3">• <strong>{initStrings.personal_details}:</strong> {initStrings.when_you_cancel_your_membership__your_account_in_t};</p>
                                </li>
                                <li className="mt-3 ml-4">
                                    <p className="mt-3">• <strong>{initStrings.address_data}:</strong> {initStrings.knaek_stores_the_address_details_for_its_own_finan};</p>
                                </li>
                                <li className="mt-3 ml-4">
                                    <p className="mt-3">• <strong>{initStrings.bank_details}:</strong> {initStrings.knaek_stores_the_bank_details_for_its_own_finan};</p>
                                </li>
                                <li className="mt-3 ml-4">
                                    <p className="mt-3">• <strong>{initStrings.email_addresses}:</strong> {initStrings.we_will_use_this_information_until_you_withdraw_yo};</p>
                                </li>
                                <li className="mt-3 ml-4">
                                    <p className="mt-3">• <strong>{initStrings.other_personal_data_that_you_actively_provide__for}:</strong> {initStrings.we_will_use_this_information_until_you_withdraw_yo2}</p>
                                </li>
                            </ul>





                            <h2 className="mt-3">{initStrings.sharing_personal_data_with_third_parties}</h2>
                            <p className="mt-3">{initStrings.knaek_promotions_does_not_sell_your_data_to_t}</p>
                            <h2 className="mt-3">{initStrings.cookies__or_similar_techniques__that_we_use}</h2>
                            <p className="mt-3" dangerouslySetInnerHTML={{__html: initStrings.knaek_promotions_does_not_use_cookies_or_simi}}>
                            </p>
                            <h2 className="mt-3">{initStrings.view__modify_or_delete_data}</h2>
                            <p className="mt-3">{initStrings.you_have_the_right_to_view__change_or_delete_your}</p>
                            <p className="mt-3" dangerouslySetInnerHTML={{__html: initStrings.you_can_send_a_request_for_inspection__correction_}}>
                            </p>
                            <p className="mt-3">{initStrings.to_ensure_that_the_request_for_access_has_been_mad}</p>
                            <p className="mt-3" dangerouslySetInnerHTML={{__html: initStrings.knaek_promotions_would_also_like_to_point_out }}></p>
                            <h2 className="mt-3">{initStrings.how_we_protect_personal_data}</h2>
                            <p className="mt-3" dangerouslySetInnerHTML={{__html: initStrings.knaek_promotions_takes_the_protection_of_your}}>
                            </p>
                            <h2 className="mt-3">{initStrings.change_privacy_statement}</h2>
                            <p className="mt-3" dangerouslySetInnerHTML={{__html: initStrings.knaek_promotions_is_at_all_times_authorized_t}}>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}


