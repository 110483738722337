import React, {useEffect, useState} from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import KnaekPrimaryButton from "../../components/AppComponents/KnaekPrimaryButton";
import {checkOneTimeAuthTokenCall, updatePasswordWithOneTimeTokenCall} from "../../api/ApiCalls";
import {useParams} from "react-router-dom";
import {successAlertFunction, tokenErrorAlertFunction} from "../../uitls/AlertFunctions";
import {validatePassword} from "../../uitls/DataValidator";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';
import {Helmet} from "react-helmet";


export default function Forgot() {
    const {token} = useParams();
    const [newPassword, setNewPassword] = useState(undefined);
    const [newPassword2, setNewPassword2] = useState(undefined);
    const [isAuthGood, setIsAuthGood] = useState(false);
    const handleLinkClick = useHandleLinkClick();


    const initStrings = {
        enter_your_new_password: stringsTranslate(appWords.enter_your_new_password),
        new_password: stringsTranslate(appWords.new_password),
        new_password_again: stringsTranslate(appWords.new_password_again),
        confirm: stringsTranslate(appWords.btn_text_confirm),
        forgot_password: stringsTranslate(appWords.forgot_password),
    }

    useEffect(() => {
        checkOneTimeAuthToken()
    }, [])

    return (
        <section className="section align-items-center justify-content-center mt-5">
            <Helmet>
                <title>Knaek - {initStrings.forgot_password}</title>
            </Helmet>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12 bg-white">

                        <div className={"text-left mt-5 mb-3 bg-gray p-5 radius-25"}>
                            <h4 className="my-4">{initStrings.enter_your_new_password}</h4>

                            <li className="list-group-item radius-15 contact-form-fields-div border-0 my-4">
                                <label>{initStrings.new_password}</label>
                                <input name="first" type="password"
                                       className="form-control bg-transparent shadow-none"
                                    onChange={(val) => {setNewPassword(val.target.value)}}
                                />
                            </li>


                            <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                <label>{initStrings.new_password_again}</label>
                                <input name="second" type="password"
                                       className="form-control bg-transparent shadow-none"
                                       onChange={(val) => {setNewPassword2(val.target.value)}}
                                />
                            </li>

                            <div className="mt-4 text-right">
                                <KnaekPrimaryButton disabled={!isAuthGood} title={initStrings.confirm} onClick={()=>{
                                    updatePassword()
                                }}/>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );

    async function checkOneTimeAuthToken() {
        try {
            let response = await checkOneTimeAuthTokenCall({token})
            if (response.valid) setIsAuthGood(true)
            if (!response.valid) {
                tokenErrorAlertFunction(stringsTranslate(appWords.link_expired), "")
                setTimeout(() => {
                    handleLinkClick('/')
                }, 4000)
            }

        } catch (e) {
            tokenErrorAlertFunction(stringsTranslate(appWords.link_expired), "")

            setTimeout(() => {
                handleLinkClick('/')
            }, 4000)
            console.log(e)
        }
    }


    async function updatePassword() {
        setIsAuthGood(false);

        // Check for minimum password length
        if (newPassword.length < 8) {
            setIsAuthGood(true);
            tokenErrorAlertFunction(stringsTranslate(appWords.password_not_valid), "");
            return; // exit function early
        }

        // Check if passwords match
        if (newPassword !== newPassword2) {
            setIsAuthGood(true);
            tokenErrorAlertFunction(stringsTranslate(appWords.password_do_not_match), "");
            return; // exit function early
        }

        // Attempt to update password if there's a token and the two conditions above are met
        if (token) {
            try {
                let response = await updatePasswordWithOneTimeTokenCall({ token, password: newPassword, password2: newPassword2 });
                successAlertFunction(stringsTranslate(appWords.password_updated), "");
                setTimeout(() => {
                    handleLinkClick('/')
                }, 4000);
            } catch (e) {
                setIsAuthGood(true);
                tokenErrorAlertFunction(stringsTranslate(appWords.update_password_error), ""); // Distinct error message for API error
                setTimeout(() => {
                    handleLinkClick('/')
                }, 4000);
                console.log(e);
            }
        } else {
            setIsAuthGood(true);
            tokenErrorAlertFunction(stringsTranslate(appWords.update_password_error), ""); // Assuming the only reason left is a missing or invalid token
        }
    }

}
