import React from 'react';
import RequestYourKnaekID from "../../components/AppSections/HomepageSections/RequestYourKnaekID";
import FooterSection from "../../components/FooterSection/Footer";
import PrivacyContent from "../../components/AppSections/Privacysection/PrivacyContent";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";

export default function TermsAndConditions() {
    const privacy_policy_cl = stringsTranslate(appWords.privacy_policy_cl);
    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {privacy_policy_cl}</title>
            </Helmet>
            <div className="main">
                <PrivacyContent />
                <RequestYourKnaekID />
                <FooterSection/>
            </div>
        </div>
    );
}
