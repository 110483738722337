import React, {useEffect, useRef, useState} from 'react';
import StoreDiscountCard from "../AppComponents/StoreDiscountCard";
import KnaekPrimaryButton from "../AppComponents/KnaekPrimaryButton";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import SnipTechOnlineDiscountCard from "../AppComponents/SniptechOnlineDiscountCard";
import NewLocalStoresDiscountCard from "../AppComponents/NewLocalStoresDiscountCard";

const initStrings = {
    section1heading: "Lokale kortingen",
    section1CounterText: "resultaten",
    sectionSearchHeading: "resultaten",
    sectionFilterHeading: "Studentenstad",
    searchPlaceholder: "Zoek een lokale korting",
    load_more_button:stringsTranslate(appWords.load_more_button)
}

export default function ListItemsStoreDiscounts({storeDiscounts}) {
    const [visibleItems, setVisibleItems] = useState(20); // Number of initially visible items
    const [itemsPerLoad, setItemsPerLoad] = useState(20); // Number of items to load per load more action
    const [allItemsLoaded, setAllItemsLoaded] = useState(false); // Flag to indicate if all items are loaded

    const loadMoreButtonRef = useRef(null); // Reference to the load more button


    // Function to handle scroll event
    const handleScroll = () => {
        if (
            !allItemsLoaded &&
            loadMoreButtonRef.current &&
            loadMoreButtonRef.current.getBoundingClientRect().top <=
            window.innerHeight
        ) {
            // Load more button is visible in the view
            loadMoreItems();
        }
    };

    useEffect(() => {
        // Add event listener when the component mounts
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once

    useEffect(() => {
        // Check if all items are loaded
        if (visibleItems >= storeDiscounts.length) {
            setAllItemsLoaded(true);
        }
    }, [visibleItems, storeDiscounts.length]);

    return (
        <div className="col-lg-10 col-12 text-left border-top border-bottom">
            <section className={"row justify-content-center pt-5"}>
                {storeDiscounts.slice(0, visibleItems).map((item, i) => (
                    <div className="store-discount-card" key={i}>
                        {/*<StoreDiscountCard item={item}  isFavorite={false}/>*/}
                        <NewLocalStoresDiscountCard item={item} isFavorite={false}/>
                    </div>
                ))}
                <br/>

            </section>
            {!allItemsLoaded && (
                <div className={"col-12 d-flex justify-content-center align-content-center align-items-center"}>
                    <KnaekPrimaryButton
                        className={"col-3 mt-2 mb-4"}
                        ref={loadMoreButtonRef}
                        onClick={()=> loadMoreItems() }
                        title={appWords.load_more_button}
                    />
                </div>

            )}
        </div>
    );

    // Function to load more items
    function loadMoreItems() {
        if (!allItemsLoaded) {
            setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerLoad);
        }
    };

}
