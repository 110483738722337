import React from "react";


export default function BannerReviews() {
    return (
        <section id="contact-form" className="section bg-white border-bottom ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6 mt-5">
                        <div className="row justify-content-start">
                            <div className="logo-in-reviews d-flex ml-3">
                                <img src="/assets/images/ReviewLogo.jpg" alt="logo" style={{maxHeight: '42px', maxWidth: '42px'}}/>
                                <div className="ml-2">
                                    <h4>
                                        Knaek
                                        <p>
                                            www.knaek.nl
                                        </p>
                                    </h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
