import React from 'react';
import AboutUsIntroText from "./AboutUsIntroText";
import AboutUsDetailsSection from "./AboutUsDetailsSection";
import YourValueCAT from "./YourValueCAT";


export default function AboutUsIntroSection() {


    return (
        <div id="screenshots" className="section mt-5 partner-section  mb-5">
            <AboutUsIntroText/>
            <AboutUsDetailsSection/>
            <YourValueCAT/>
        </div>
    );
}
