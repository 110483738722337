import React from 'react';
import FooterSection from '../../components/FooterSection/Footer';
import KnaekVacancys from "../../components/AppSections/WorkInKnaek/KnaekVacancys";
import VacancysType from "../../components/AppSections/WorkInKnaek/VacancysType";
import ApplyJobCAT from "../../components/AppSections/WorkInKnaek/ApplyJobCAT";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";

export default function KnaekVacancy() {
    const vacancies = stringsTranslate(appWords.vacancies)
    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {vacancies}</title>
            </Helmet>
            <div className="main">
                <KnaekVacancys/>
                <VacancysType/>
                <ApplyJobCAT/>
                <FooterSection/>
            </div>
        </div>
    );
}
