import React, {useEffect, useState} from 'react';
import IntroKnaekInfoSection from "../../components/AppSections/IntroKnaekSections/IntroKnaekInfoSection";
import {useParams} from "react-router-dom";
import {getIntroCall} from "../../api/ApiCalls";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";

export default function Intro() {
    const {name} = useParams();
    const [introData, setIntroData] = useState(undefined)
    const intro_welcome_header_discount = stringsTranslate(appWords.intro_welcome_header_discount);

    useEffect(() =>{
        getIntro()
    },[name])

    return (
        <div className="homepage-4 pt-4 mt-5">
            <Helmet>
                <title>Knaek - {intro_welcome_header_discount}</title>
            </Helmet>
            <div className="main">
                {introData && <IntroKnaekInfoSection introData={introData} />}
                {!introData && <h2>Intro</h2>}

            </div>
        </div>
    );

    async function getIntro() {
        try {
            let response = await getIntroCall(name)
            if(!response?.isActive) return
            setIntroData(response)
        } catch (e) {

        }
    }
}
