import React, {useRef} from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ImageComponent from "../AppComponents/ImageComponent";
import {useParams} from "react-router-dom";
import {hasAValue} from "../../uitls/SharedFunctions";


export default function BreadcrumbRouteNav({element1, element2, element3, colorWhite}) {
    let whiteArrow = "/assets/images/icons/arrow-white.png"
    let arrow = "/assets/images/icons/arrow.png"
    return (
        <div className="col-12 py-2 px-0 m-0 pl-0">
            <Breadcrumb className={"pl-0"}>
                <Breadcrumb.Item href="/" className={"pl-0"}>
                    <svg width="18" viewBox="0 0 32 32" className={`${colorWhite? 'text-white pl-0 ml-0': 'color-primary pl-0  ml-0'} `}>
                        <path fill={`${colorWhite? '#fff': ''}`}
                            d="M31.62,16.83,17.09,2.09A1.06,1.06,0,0,0,15.59,2l-.06.06L.35,16.81A1.07,1.07,0,0,0,.09,18a1.08,1.08,0,0,0,1.07.68H3.71V29.13a1.07,1.07,0,0,0,1,1.12h7.67a1.06,1.06,0,0,0,1.11-1,.21.21,0,0,0,0-.11V21.51h4.65v7.62a1.13,1.13,0,0,0,1.11,1.12h7.36a1.13,1.13,0,0,0,1.06-1.12V18.72h3.2A1.07,1.07,0,0,0,31.94,18,1.13,1.13,0,0,0,31.62,16.83Zm-5-.33a1.06,1.06,0,0,0-1.12,1V28H20.43V20.4a1.06,1.06,0,0,0-1-1.12h-7a1.08,1.08,0,0,0-1.07,1.07s0,0,0,0V28H5.94V17.61a1.07,1.07,0,0,0-1-1.11H3.86l12.41-12,11.89,12Z"></path>
                    </svg>
                </Breadcrumb.Item>

                {hasAValue(element1) && <><span className="mx-2"><ImageComponent className={""} src={`${colorWhite? whiteArrow: arrow} `} width="5" height="9"/></span>
                <Breadcrumb.Item active>
                    <a href={"/"+element1} className={`${colorWhite? 'text-white': ''} cursor-pointer`}>{element1}</a>
                    </Breadcrumb.Item></>}

                {hasAValue(element2) && <>  <span className="mx-2"><ImageComponent className={""} src={`${colorWhite? whiteArrow: arrow} `} width="5" height="9"/></span>
                <Breadcrumb.Item active>
                    <a href={"/"+element1} className={`${colorWhite? 'text-white': ''} cursor-pointer`}>{element2}</a>
                </Breadcrumb.Item></>}

                {hasAValue(element3) && <>
                <span className="mx-2"><ImageComponent className={""} src={`${colorWhite? whiteArrow: arrow} `} width="5" height="9"/></span>
                <Breadcrumb.Item  className={`${colorWhite? 'text-white': ''} cursor-pointer`}>{element3}</Breadcrumb.Item>
                </>}
            </Breadcrumb>
        </div>
    );
}
