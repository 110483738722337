import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useHandleLinkClick = () => {
    const history = useHistory();

    const handleLinkClick = useCallback((link) => {
        const timeoutId = setTimeout(() => {
            history.push(link);
            window.scrollTo(0, 0);
        }, 300);

        // Cleanup timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, [history]);

    return handleLinkClick;
};