import React, {Component} from 'react';
import SliderPartners from "../../AppCustomizedComponent/SliderPartners";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";






export default function Partners() {

    const initStrings = {
        "heading": stringsTranslate(appWords.our_partners_heading),
        "headingTexttwo": "",
        "teamData": [
            {
                "id": 1,
                "image": "/assets/images/partners/new/Amsterdam_HogeschoolVanAmsterdam.webp",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/new/Amsterdam_HotelschoolTheHague.webp",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/new/Amsterdam_Inholland.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/new/Antwerpen_KarelDeGrote.webp",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/new/Arnhem_ArtEZ.webp",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/new/Breda_BUAS.webp",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/new/Delft_TUDelft.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/new/DenBosch_Avans.webp",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/new/DenBosch_JADS.webp",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/new/DenHaag_HaagseHogeschool.webp",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/new/Eindhoven_TUEindhoven.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/new/Enschede_Saxion.webp",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/new/Amsterdam_HotelschoolTheHague.webp",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/new/Enschede_UniversiteitTwente.webp",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/new/Groningen_HanzeHogeschool.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/new/Groningen_RUG.webp",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/new/Leeuwarden_NHLStenden.webp",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/new/Leeuwarden_VanHallLarenstein.webp",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/new/Leiden_HogeschoolLeiden.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/new/Leuven_KULeuven.webp",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/new/Maastricht_University.webp",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/new/Maastricht_Zuyd.webp",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/new/Nijmegen_RadboudUniversiteit.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/new/Rotterdam_HogeschoolRotterdam.webp",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/new/Tilburg_Fontys.webp",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/new/Utrecht_HogeschoolUtrecht.webp",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/new/Utrecht_UniversiteitUtrecht.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/new/Wageningen_WUR.webp",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/new/Zwolle_Viaa.webp",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/new/Zwolle_Windesheim.webp",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/Partner_1.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/Partner_2.webp",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/Partner_3.jpeg",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/Partner_4.jpeg",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/Partner_6.jpg",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/Partner_9.jpg",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/Partner_10.jpg",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/new/Rotterdam_HogeschoolRotterdam.webp",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/Partner_26.png",
            },

            {
                "id": 1,
                "image": "/assets/images/partners/Partner_11.jpg",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/Partner_12.jpg",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/Partner_13.jpg",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/Partner_14.jpg",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/Partner_15.jpg",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/Partner_16.jpg",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/Partner_17.jpg",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/Partner_18.jpg",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/Partner_19.png",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/Partner_20.png",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/Partner_21.png",
            },
            {
                "id": 4,
                "image": "/assets/images/partners/Partner_22.png",
            },
            {
                "id": 1,
                "image": "/assets/images/partners/Partner_23.png",
            },
            {
                "id": 2,
                "image": "/assets/images/partners/Partner_24.png",
            },
            {
                "id": 3,
                "image": "/assets/images/partners/Partner_25.png",
            },


        ]
    }

    return (

        <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-12 mt-5 mb-2 col-lg-12">
                        {/* Section Heading */}
                        <div className="text-center">
                            <h2 className="mb-5">{initStrings.heading}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <SliderPartners data={initStrings.teamData}/>
                </div>
        </div>
    );
}
