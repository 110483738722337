import React, {Component} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import Unsubscribe from "../../../pages/Unsubscription/Unsubscribe";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import { getCurrentYear, theNewYearOfKnaek } from '../../../uitls/SharedFunctions';





export default function UnsubscribeInfoSection({setCancellationInfoShow}) {

    const initStrings = {

        plyImage: "/assets/images/account/map-popup.png",
        content: stringsTranslate(appWords.unsubscribe_section_content),
        content1: stringsTranslate(appWords.unsubscribe_section_content_1),
        content2: stringsTranslate(appWords.unsubscribe_section_content_2,{yearBefore:theNewYearOfKnaek(), year:theNewYearOfKnaek()}),
        btnText: stringsTranslate(appWords.unsubscribe_btn_text),

    }


    return (
        <section className="section">
            <div className="container">
                <div className="row h-100vh justify-content-center">
                    <div className="col-lg-8 col-10 px-3 shadow">
                        <div className="text-center align-items-center mb-5 justify-content-center ">
                            <img  src={initStrings.plyImage} alt={""}/>
                            <p className="mb-5">{initStrings.content}</p>
                            <p className="mt-5 font-weight-bolder">{initStrings.content1}</p>
                            <p className="mt-5 mb-5 font-weight-bolder">{initStrings.content2}</p>
                            <div className={"d-flex justify-content-center"}>
                                <div className="mt-5 mb-5 divider-horizontal px-5"/>
                            </div>
                            <KnaekPrimaryButton
                                props={""}
                                onClick={() => {setCancellationInfoShow()}}
                                title={initStrings.btnText}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}


