import React, {useEffect, useRef, useState} from 'react';
import KnaekPrimaryButton from "../AppComponents/KnaekPrimaryButton";
import JobsCard from "../AppComponents/JobsCard";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import NewJobCard from "../AppComponents/NewJobCard";

export default function ListItemsJobs({jobsRS}) {
    let initStrings = {
        load_more_button:stringsTranslate(appWords.load_more_button)
    }
    const [visibleItems, setVisibleItems] = useState(20); // Number of initially visible items
    const [itemsPerLoad, setItemsPerLoad] = useState(20); // Number of items to load per load more action
    const [allItemsLoaded, setAllItemsLoaded] = useState(false); // Flag to indicate if all items are loaded

    const loadMoreButtonRef = useRef(null); // Reference to the load more button


    // Function to handle scroll event
    const handleScroll = () => {
        if (
            !allItemsLoaded &&
            loadMoreButtonRef.current &&
            loadMoreButtonRef.current.getBoundingClientRect().top <=
            window.innerHeight
        ) {
            // Load more button is visible in the view
            loadMoreItems();
        }
    };

    useEffect(() => {
        // Add event listener when the component mounts
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once

    useEffect(() => {
        // Check if all items are loaded
        if (visibleItems >= jobsRS.length) {
            setAllItemsLoaded(true);
        }
    }, [visibleItems, jobsRS.length]);

    return (
        <div className="col-lg-10 col-12 text-left border-top border-bottom">
            <section className={"row justify-content-center pt-5"}>
                {jobsRS.slice(0, visibleItems).map((item, i) => (
                    <div className="store-discount-card" key={i}>
                        {/*<JobsCard item={item} />*/}
                        <NewJobCard item={item} />
                    </div>
                ))}
            </section>
            {!allItemsLoaded && (
                <div className={"col-12 d-flex justify-content-center align-content-center align-items-center"}>
                    <KnaekPrimaryButton
                        className={"col-3 mt-2 mb-4"}
                        title={initStrings.load_more_button}
                        ref={loadMoreButtonRef}
                        onClick={()=> loadMoreItems() }
                    />
                </div>

            )}
        </div>
    );

    // Function to load more items
    function loadMoreItems () {
        if (!allItemsLoaded) {
            setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerLoad);
        }
    }
}
