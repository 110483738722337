import React from "react";
import BannerSectionForCompaies from "../../components/AppSections/PartnersOfKnaek/BannerSectionForCompaies";
import BetweenSectionForCompanies from "../../components/AppSections/ForCompanies/BetweenSectionForCompanies";
import TextSectionForCompaies from "../../components/AppSections/ForCompanies/TextSectionForCompaies";
import FooterSection from "../../components/FooterSection/Footer";
import KnaekIDUsers from "../../components/AppSections/ForCompanies/KnaekIDUsers";
import PricingCard from "../../components/AppSections/ForCompanies/PricingCard";
import CTACompanies from "../../components/AppSections/HomepageSections/CTACompanies";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";


export default function ForCompanies() {
    const for_companies_cl = stringsTranslate(appWords.for_companies_cl)
    return (
        <div className={"homepage-4 pt-5 mt-5"}>
            <Helmet>
                <title>Knaek - {for_companies_cl}</title>
            </Helmet>
            <div className={"main"}>
                <BannerSectionForCompaies/>
                <BetweenSectionForCompanies/>
                <KnaekIDUsers/>
                <TextSectionForCompaies/>
                <PricingCard/>
                <CTACompanies/>
                <FooterSection/>
            </div>
        </div>
    );
}
