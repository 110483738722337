import React from 'react';
import ImageComponent from "./ImageComponent";
import {hasAValue} from "../../uitls/SharedFunctions";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';

export default function FavoriteDiscountCard({item}) {
    const handleLinkClick = useHandleLinkClick();
    
    return (
        <div className={item.activeDiscountId ? "card radius-15 border-0 store-discount-card cursor-pointer shadow" : "card radius-15 border-0 store-discount-card shadow" } onClick={() => goToInfo()}>
            <ImageComponent src={item.backgroundImageUrl} className="homepage-online-section-online-card-background" alt=""/>

            <div className="card-body row store-discount-card-body">
                <div className={"col-12 d-flex justify-content-center"}>
                    <ImageComponent src={item.logoUrl}
                                    className="bg-white radius-15 online-discount-card-logo shadow d-flex justify-content-center align-items-center"
                                    alt="" height={98} width={98}/>

                </div>
                <div className={"col-12 text-center"}>
                    <h4 className="w-100 mt-2 text-knaek-title d-none d-md-block">{item.name}</h4>
                </div>
            </div>
        </div>

    );

    function goToInfo() {
        if (!hasAValue(item.activeDiscountId))  return;

        handleLinkClick("/studentenkorting/" + item.cityName + "/"  + item.name + "/" + item.activeDiscountId)
    }
}



