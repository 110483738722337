import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import KnaekPrimaryButton from "./KnaekPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAuthDataNEWRS,
  updateAuthDataRS,
  updateEmailInModal,
  updateShowAuthModalRS,
  updateShowLoginLinkModal,
} from "../../redux/reduxActions/reduxStoreActions";
import {
  loginFunctionCall,
  passwordForgetFunctionCall,
} from "../../api/ApiCalls";
import { validateEmail, validatePassword } from "../../uitls/DataValidator";
import { hasAValue } from "../../uitls/SharedFunctions";
import { stringsTranslate } from "../../locales/CustomTranslater";
import { appWords } from "../../config/Constants";
import { afterLoginAlertFunction } from "../../uitls/AlertFunctions";
import { useHandleLinkClick } from "../../hooks/useHandleLinkClick";

export default function LoginComponent() {
  const showAuthModalRS = useSelector((state) => state.showAuthModalRS);
  const dispatch = useDispatch();

  // Variables
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  // const [tel_number, setTel_number] = useState(undefined)
  // const [first_name, setFirst_name] = useState(undefined)
  // const [last_name, setLast_name] = useState(undefined)
  const [showLogin, setShowLogin] = useState(true);

  // Errors
  // const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(undefined);

  const initString = {
    header: stringsTranslate(appWords.login_component_title),
    emailLabel: stringsTranslate(appWords.email_label),
    emailPlaceholder: stringsTranslate(appWords.email_placeholder),
    passwordLabel: stringsTranslate(appWords.password_label),
    passwordPlaceholder: stringsTranslate(appWords.password_placeholder),
    registerLinkLabel: stringsTranslate(appWords.create_kanek_account),
    passwordForgetText: showLogin
      ? stringsTranslate(appWords.forgot_password)
      : stringsTranslate(appWords.login_sl),
    reset_pass_button: stringsTranslate(appWords.reset_pass_button),
    error_message: stringsTranslate(appWords.error_login_message),
    email_login_link: (stringsTranslate(appWords.email_login_link)).toString().toUpperCase(),
  };

  const handleLinkClick = useHandleLinkClick();

  return (
    <Modal
      className="modal fade radius-15 mt-4"
      size={"lg"}
      show={showAuthModalRS}
      onHide={() => {
        setErrorMessage("");
        dispatch(updateShowAuthModalRS(false));
      }}
    >
      <div className="radius-15" role="document">
        <div className="radius-15">
          <div className={"radius-15 pt-3 bg-light"}>
            <div
              className="card-header bg-light border-0 d-flex justify-content-end cursor-pointer"
              onClick={() => {
                setErrorMessage("");
                dispatch(updateShowAuthModalRS(false));
              }}
            >
              <svg
                className=" mt-3 mr-3"
                width="15"
                height="15"
                viewBox="0 0 32 32"
              >
                <path
                  fill="black"
                  d="M31.29,27.48a2.7,2.7,0,0,1-3.81,3.81L16,19.83,4.52,31.29A2.7,2.7,0,0,1,.71,27.48L12.17,16,.71,4.52A2.7,2.7,0,0,1,4.52.71L16,12.17,27.48.71a2.7,2.7,0,0,1,3.81,3.81L19.83,16Z"
                />
              </svg>
            </div>
            <div className="modal-body  radius-15">
              <div className="card border-top border-0">
                <div className="card-header bg-light border-0 d-flex justify-content-center">
                  <h1
                    className="card-title text-center"
                    style={{ width: "90%" }}
                  >
                    {initString.header}
                  </h1>
                </div>

                <div className="card-body bg-light pb-0">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item radius-15 login-fields-div border-0 my-2">
                      <label className={""}>{initString.emailLabel}</label>
                      <input
                        type="email"
                        className="form-control bg-transparent shadow-none"
                        placeholder={initString.emailPlaceholder}
                        onChange={(val) => setEmail(val.target.value)}
                      />
                    </li>

                    {hasAValue(errorMessage) && (
                      <label className={"my-3 ml-2 text-danger"}>
                        {errorMessage}
                      </label>
                    )}
                    <li className="bg-light d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center">
                      <div
                        className={
                          "w-100 order-0 order-lg-1 d-flex justify-content-center"
                        }
                      >
                        <KnaekPrimaryButton
                          title={
                            showLogin
                              ? initString.email_login_link
                              : initString.reset_pass_button
                          }
                          className={" mt-2 d-flex justify-content-center"}
                          onClick={() =>
                            showLogin
                              ? loginFunction()
                              : passwordForgetFunction()
                          }
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="modal-footer mx-5 mt-3 justify-content-start align-items-start pl-0">
              <div className="mb-4">
                <p className={"text-knaek-regular"}>
                  {/*{initString.registerLinkLabel}*/}
                  <a
                    className={
                      "text-lg-left text-center text-primary font-weight-bold cursor-pointer"
                    }
                    onClick={() => {
                      dispatch(updateShowAuthModalRS(false));
                      handleLinkClick("/bestellen");
                    }}
                  >
                    {initString.registerLinkLabel}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  function loginFunction() {
    let data = {};
    data["email"] = email;
    // data["password"] = password;
    // data["device"] = {
    //   systemVersion: "knaek-website",
    //   model: "knaek-website",
    //   identifier: "knaek-website",
    // };

    if (validateEmail(email)) {
      loginFunctionCall(data)
        .then((result) => {
          // dispatch(updateAuthDataNEWRS(result.data));
          // dispatch(updateShowAuthModalRS(false));
          // afterLoginAlertFunction();
          dispatch(updateEmailInModal(email));
          dispatch(updateShowLoginLinkModal(true));
          dispatch(updateShowAuthModalRS(false));
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(initString.error_message);
        });
    } else {
      // Handle validation errors here
      console.log("Data validation failed");
    }
  }

  function passwordForgetFunction() {
    let data = {};
    data["email"] = email;

    if (validateEmail(email)) {
      passwordForgetFunctionCall(data)
        .then((result) => {
          setShowLogin(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // Handle validation errors here
      console.log("Data validation failed");
    }
  }
}
