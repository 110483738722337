import React, {useEffect, useState} from 'react';
import {fetchMyTransactionSnipTechCall, fetchSnipTechBalancesCall, fetchMyTicketsSnipTechCall, fetchMyPayoutsSnipTechCall} from "../../../api/ApiCalls";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appColors, appWords} from "../../../config/Constants";
import ClaimCashbackComponent from "../../AppComponents/ClaimCashbackComponent";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import PayoutModalComponent from "../../AppComponents/PayoutModalComponent";
import {failedAlertFunction} from "../../../uitls/AlertFunctions";
import {useSelector} from "react-redux";
import {dateFormat} from "../../../uitls/SharedFunctions";

export default function SavedOnlineDiscountsProfileSection() {
    const initStrings = {
        online_saved: stringsTranslate(appWords.online_saved),
        you_have_in_total_1: stringsTranslate(appWords.you_have_in_total_1),
        you_have_in_total_2: stringsTranslate(appWords.you_have_in_total_2),
        you_have_in_total_3: stringsTranslate(appWords.you_have_in_total_3),
        Where_is_my_transaction: stringsTranslate(appWords.Where_is_my_transaction),
        knaek_approved: stringsTranslate(appWords.knaek_approved),
        knaek_pending: stringsTranslate(appWords.knaek_pending),
        knaek_declined: stringsTranslate(appWords.knaek_declined),
        knaek_open: stringsTranslate(appWords.knaek_open),
        knaek_answered: stringsTranslate(appWords.knaek_answered),
        knaek_closed: stringsTranslate(appWords.knaek_closed),
        knaek_new: stringsTranslate(appWords.knaek_new),
        claimCashback: (stringsTranslate(appWords.claimCashback)).toString().toUpperCase(),
        payout: (stringsTranslate(appWords.payout)).toString().toUpperCase(),
        noBalanceForPayout: stringsTranslate(appWords.noBalanceForPayout),
        transactions_cc: stringsTranslate(appWords.transactions_cc),
        claims: stringsTranslate(appWords.claims),
        payouts: stringsTranslate(appWords.payouts),
        cashbackAmountInvalid: stringsTranslate(appWords.cashbackAmountInvalid),
        purchaseNotRegistered: stringsTranslate(appWords.purchaseNotRegistered),
        purchaseRejected: stringsTranslate(appWords.purchaseRejected),
        details: stringsTranslate(appWords.details),
        status: stringsTranslate(appWords.status),
        amount: stringsTranslate(appWords.amount),
        wallet: stringsTranslate(appWords.wallet),
        creation: stringsTranslate(appWords.creation),
        paid_at: stringsTranslate(appWords.paid_at),
        converted_at: stringsTranslate(appWords.converted_at),
        type: stringsTranslate(appWords.type),
        name: stringsTranslate(appWords.name),
        cashback: stringsTranslate(appWords.cashback),
        description: stringsTranslate(appWords.description),
        it_may_take_48_hours_before_your_cashback_is_visible_here: stringsTranslate(appWords.it_may_take_48_hours_before_your_cashback_is_visible_here),
    }

    const [showClaimModal, setShowClaimModal] = useState(false);
    const [sniptechTransactions, setSniptechTransactions] = useState([]);
    const [sniptechPayouts, setSniptechPayouts] = useState([]);
    const [sniptechTickets, setSniptechTickets] = useState([]);
    const [sniptechBalance, setSniptechBalance] = useState({})
    const [sniptechHoverIndex, setSniptechHoverIndex] = useState(null);
    const [showPayoutModal, setShowPayoutModal] = useState(false);
    const [activeTabOffset, setActiveTabOffset] = useState(0);

    useEffect(() => {
        checkAnchor()
        fetchMySniptechTransactionOnline()
    }, [])


    const appLanguageRS = useSelector(state => state.appLanguageRS);

    const formatNumber = (number) => {
        let contentLanguage = appLanguageRS === "en"? "en": "nl"
        return new Intl.NumberFormat(contentLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
    };
    const claimStatusBadge = (status) => {
        const normalizedStatus = status?.toString().toLowerCase();
        const statusColorMap = {
            'new': 'info',
            'open': 'success',
            'answered': 'warning',
            'closed': 'danger'
        };
        
        for (const key in statusColorMap) {
            if (normalizedStatus.includes(key)) {
                return statusColorMap[key];
            }
        }
    }
    const claimStatus = (status) => {
        
        const normalizedStatus = status?.toString().toLowerCase();
        const statusMap = {
            'new': initStrings.knaek_new,
            'open': initStrings.knaek_open,
            'answered': initStrings.knaek_answered,
            'closed': initStrings.knaek_closed
        };
        
        for (const key in statusMap) {
            if (normalizedStatus.includes(key)) {
                return statusMap[key];
            }
        }
    }
    
    const claimTypeBadge = (status) => {
        
        const normalizedStatus = status?.toString().toLowerCase();
        const claimTypeColorMap = {
            'cashbackamountinvalid': 'danger',
            'purchasenotregistered': 'warning',
            'purchaserejected': 'danger'
        };
        
        for (const key in claimTypeColorMap) {
            if (normalizedStatus.includes(key)) {
                return claimTypeColorMap[key];
            }
        }
    }
    const claimType = (status) => {
        
        const normalizedStatus = status?.toString().toLowerCase();
        const claimTypeMap = {
            'cashbackamountinvalid': initStrings.cashbackAmountInvalid,
            'purchasenotregistered': initStrings.purchaseNotRegistered,
            'purchaserejected': initStrings.purchaseRejected
        };
        
        for (const key in claimTypeMap) {
            if (normalizedStatus.includes(key)) {
                return claimTypeMap[key];
            }
        }
    }
    
    const StatusBadge = (status) => {
        
        const normalizedStatus = status?.toString().toLowerCase();
        const StatusColorMap = {
            'confirmed': 'success',
            'rejected': 'danger',
            'pending': 'info'
        };
        
        for (const key in StatusColorMap) {
            if (normalizedStatus.includes(key)) {
                return StatusColorMap[key];
            }
        }
    }
    const Status = (status) => {
        const normalizedStatus = status?.toString().toLowerCase();
        const StatusMap = {
            'confirmed': initStrings.knaek_approved,
            'rejected': initStrings.knaek_declined,
            'pending': initStrings.knaek_pending
        };
        
        for (const key in StatusMap) {
            if (normalizedStatus.includes(key)) {
                return StatusMap[key];
            }
        }
    }
    
    const checkAnchor = () => {
        
        const anchor = window.location.href.toString()

        switch (anchor) {
            case "openClaim":
                setShowClaimModal(true)
                break;
            case 'transactions':
                setActiveTabOffset(0)
                break;
            case 'claims':
                setActiveTabOffset(1)
                break;
            case 'payouts':
                setActiveTabOffset(2)
                break;
        }

    }

    return (
        <div className={"mt-4 ml-4"}>
            <h1>{initStrings.online_saved}</h1>
            <div className={"divider-horizontal"}/>
            <div className="row">
                <div className={"col-lg-8 col-sm-8 d-flex justify-content-start flex-wrap"}>
                    <div className={"card card-body radius-15 border-0 w-100 in-store-saved-discount-card-body"}>
                        <div className="row">
                            <div className={"col-12 justify-content-center align-items-center"}>
                                <a>
                                    {initStrings.you_have_in_total_1}
                                    <span className="font-weight-bolder text-primary h4">
                                        €{formatNumber(sniptechBalance?.savedAmount)}
                                    </span>
                                    {initStrings.you_have_in_total_2}
                                    <span className="font-weight-bolder text-primary h4">
                                        €{formatNumber(sniptechBalance?.payableAmount)}
                                     </span>
                                    {initStrings.you_have_in_total_3}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-3 d-flex justify-content-end align-items-center mt-sm-0 mt-3">
                    <KnaekPrimaryButton
                        title={initStrings.payout}
                        className={" mt-2"}
                        onClick={checkPayout}
                    />
                </div>
            </div>

            <div className="col-sm-11 col-lg-11 mt-5 mb-3 d-flex justify-content-center claim-model-trigger">
                <span className={"text-primary cursor-pointer"}
                      onClick={() => setShowClaimModal(true)}>{initStrings.Where_is_my_transaction}</span>
            </div>
            <div className="col-sm-11 col-lg-11 mb-3 mt-5 d-flex justify-content-center">
                <span className={"text-primary"}>{initStrings.it_may_take_48_hours_before_your_cashback_is_visible_here}</span>
            </div>

            <div className={"col-sm-12 col-lg-11 mt-3 mb-3 px-0"}>
                <div className="animated-tabs mb-5" >
                    <div className={`animated-tab ${ (activeTabOffset == 0 ? 'active' : '') }`} onClick={() => { setActiveTabOffset(0) }}>
                        {initStrings.transactions_cc}
                    </div>
                    <div className={`animated-tab ${ (activeTabOffset == 1 ? 'active' : '') }`} onClick={() => { setActiveTabOffset(1) }}>
                        {initStrings.claims}
                    </div>
                    <div className={`animated-tab ${ (activeTabOffset == 2 ? 'active' : '') }`} onClick={() => { setActiveTabOffset(2) }}>
                        {initStrings.payouts}
                    </div>
                    <div className='scrollable animated' style={{left: ( ( activeTabOffset ) * 33.3333 ) + '%'  }}></div>
                </div>
                <div className={`flex-column ${(activeTabOffset == 0 ? 'd-flex ' : 'd-none')}`}>
                    <table className='table-hoverable'>
                        <thead>
                            <tr>
                                <th>{initStrings.converted_at}</th>
                                <th>{initStrings.description}</th>
                                <th>{initStrings.cashback}</th>
                                <th>{initStrings.status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sniptechTransactions.map((item, i) => {
                                return (
                                    <tr key={i}>
                                            <td>{dateFormat(item?.converted_at)}</td>
                                            <td>{item?.description?.toString()}</td>
                                            <td>€{item.cashback}</td>
                                            <td>
                                                <span className={`p-2 badge badge-${StatusBadge(item?.status)}`}>
                                                            {Status(item?.status)}
                                                </span>
                                            </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className={`flex-column ${(activeTabOffset == 1 ? 'd-flex ' : 'd-none')}`}>
                    <table className='table-hoverable'>
                        <thead>
                            <tr>
                                <th>{initStrings.name}</th>
                                <th>{initStrings.type}</th>
                                <th>{initStrings.creation}</th>
                                <th>{initStrings.status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sniptechTickets.map((item, i) => {
                                return (
                                    <tr key={i}>
                                            <td>{item?.name}</td>
                                            <td>{claimType(item?.type)}</td>
                                            <td>{dateFormat(item?.creationDate)}</td>
                                            <td>
                                                <span className={`p-2 badge badge-${claimStatusBadge(item?.status)}`}>
                                                        {claimStatus(item?.status)}
                                                </span>
                                            </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className={`flex-column ${(activeTabOffset == 2 ? 'd-flex ' : 'd-none')}`}>
                    <table className='table-hoverable'>
                        <thead>
                            <tr>
                                <th>{initStrings.details}</th>
                                <th>{initStrings.amount}</th>
                                <th>{initStrings.wallet}</th>
                                <th>{initStrings.creation}</th>
                                <th>{initStrings.paid_at}</th>
                                <th>{initStrings.status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sniptechPayouts.map((item, i) => {
                                return (
                                    <tr key={i}>
                                            <td>{item?.details?.toString()}</td>
                                            <td>€{item?.amount}</td>
                                            <td>{item?.walletId}</td>
                                            <td>{dateFormat(item?.creationDate)}</td>
                                            <td>{dateFormat(item?.paidDate)}</td>
                                            <td>
                                                <span className={`p-2 badge badge-${StatusBadge(item?.status)}`}>
                                                        {Status(item?.status)}
                                                </span>
                                            </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {showClaimModal && <ClaimCashbackComponent onClose={() => setShowClaimModal(false)}/>}
            {showPayoutModal && <PayoutModalComponent
                onClose={() => setShowPayoutModal(false)}
                sniptechBalance={sniptechBalance}
            />}
        </div>
    );

    function checkPayout() {
        if (sniptechBalance?.payableAmount === 0) {
            failedAlertFunction(initStrings.noBalanceForPayout, '');
            return;
        }
        setShowPayoutModal(true);
    }


    async function fetchMySniptechTransactionOnline() {
        try {
            
            let transactionResponse = await fetchMyTransactionSnipTechCall();
            let balancesResponse = await fetchSnipTechBalancesCall();
            let payoutsResponse = await fetchMyPayoutsSnipTechCall();
            let ticketsResponse = await fetchMyTicketsSnipTechCall();
            if (transactionResponse && transactionResponse.length > 0) {
                setSniptechTransactions(transactionResponse)
            }
            if (payoutsResponse && payoutsResponse.length > 0) {
                setSniptechPayouts(payoutsResponse)
            }
            if (ticketsResponse && ticketsResponse.length > 0) {
                setSniptechTickets(ticketsResponse)
            }
            
            setSniptechBalance(balancesResponse)
        } catch (e) {
            console.log(e)
        }
    }
}
