import React, {useEffect, useState} from 'react';
import {appLanguage} from "../../config/Constants";
import {useDispatch, useSelector} from "react-redux";
import {updateLanguageRS} from "../../redux/reduxActions/reduxStoreActions";

export default function ReviewsHeader({switchLanguage}) {
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const dispatch = useDispatch();

    const toggleLanguage = () => {
        const newLanguage = appLanguageRS === appLanguage.nl ? appLanguage.en : appLanguage.nl;
        dispatch(updateLanguageRS(newLanguage))
        switchLanguage(newLanguage);
    };

    const [languageFlag, setLanguageFlag] = useState("");

    useEffect(() => {
        if (appLanguageRS === appLanguage.nl) {
            setLanguageFlag("https://cms.it.knaek.com/uploads/english_2b352b017b.svg");
        } else if (appLanguageRS === appLanguage.en) {
            setLanguageFlag("https://cms.it.knaek.com/uploads/nederlands_ec831d47f1.svg");
        }
    }, [appLanguageRS]);

    return (
        <header className="navbar navbar-reviews-dark navbar-custom-style">
            <div className="container-fluid ">
                <div className="col-12">
                    <div className="row justify-content-end">
                        <div className="col-lg-11 col-md-9 col-sm-11"></div>
                        <div className="col-lg-1 col-md-3 col-sm-1 d-flex cursor-pointer">
                            <a className="position-relative" onClick={toggleLanguage}>
                                <img
                                    src={languageFlag}
                                    alt="Switcher"
                                    className="position-relative" width="25" height="17"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
