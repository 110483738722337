import React, { useEffect, useState } from "react";
import { stringsTranslate } from "../../../locales/CustomTranslater";
import {
  hasAValue,
  theNewYearOfKnaek,
} from "../../../uitls/SharedFunctions";
import { appWords } from "../../../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderKnaekDataRS } from "../../../redux/reduxActions/reduxStoreActions";
import {
  validateEmailAndSubscription,
  validateStudentEmailAddressAndSubscription,
} from "../../../uitls/DataValidator";
import ArrowOrderWizardComponent from "./ArrowOrderWizardComponent";
import { validationErrorAlertFunction } from "../../../uitls/AlertFunctions";
import {postHitTrackingCall} from "../../../api/ApiCalls";

export default function OrderKnaekIDFlowStep1({
  currentStep,
  setCurrentStep,
  withStudentEmail,
}) {
  const orderKnaekDataRS = useSelector((state) => state.orderKnaekDataRS);
  const dispatch = useDispatch();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [wantToGetUpdates, setWantToGetUpdates] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
      postHitTrackingCall({event: `order-journey-1`});
  }, [currentStep]);

  const initStrings = {
    order_knaek_id: stringsTranslate(appWords.order_knaek_id),
    become_a_member_at_knaek: stringsTranslate(
      appWords.become_a_member_at_knaek
    ),
    try_knaek_till: stringsTranslate(appWords.try_knaek_till),
    try_knaek_till2: stringsTranslate(appWords.try_knaek_till2),
    i_agree_to_the_terms_and_conditions_part1: stringsTranslate(
      appWords.i_agree_to_the_terms_and_conditions_part1
    ),
    until_further_notice: stringsTranslate(
      appWords.until_further_notice
    ),
    i_agree_to_the_terms_and_conditions_link: stringsTranslate(
      appWords.i_agree_to_the_terms_and_conditions_link
    ),
    i_agree_to_the_terms_and_conditions_part2: stringsTranslate(
      appWords.i_agree_to_the_terms_and_conditions_part2
    ),
    privacy: stringsTranslate(appWords.privacy),
    email_at_school: stringsTranslate(appWords.email_at_school),
    email_private: stringsTranslate(appWords.email_private),
    enter_your_private_email_address: stringsTranslate(
      appWords.enter_your_private_email_address
    ),
    enter_your_student_email: stringsTranslate(
      appWords.enter_your_student_email
    ),
    repeat: stringsTranslate(appWords.repeat),
    email_not_valid: stringsTranslate(appWords.email_not_valid),
    email_not_available: stringsTranslate(appWords.email_not_available),
    student_email_not_valid: stringsTranslate(appWords.student_email_not_valid),
    emails_are_not_same: stringsTranslate(appWords.emails_are_not_same),
    not_all_data_are_valid: stringsTranslate(appWords.not_all_data_are_valid),
    if_you_are_user_in_the_app: stringsTranslate(
      appWords.if_you_are_user_in_the_app
    ),
    agree_to_terms_error: stringsTranslate(appWords.agree_to_terms_error),
  };

  return (
    <>
      <section
        className="section align-items-start justify-content-start mt-5  w-100 ml-2"
        style={{zIndex:"-1"}}
      >
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-12 col-sm-12 mb-5 pb-5">
              <div className={"text-left mt-5 mb-3"}>
                <h1 className="my-3">{initStrings.order_knaek_id}</h1>
                <p className={"mt-4 mb-3"}>
                  {initStrings.become_a_member_at_knaek}
                </p>
                <p>
                  {initStrings.try_knaek_till} {theNewYearOfKnaek()}
                  {initStrings.try_knaek_till2}
                </p>
              </div>

              <div className="row">
                <div className="col-12 d-flex">
                  <ul className="col-12 list-group list-group-flush">
                    <li className="list-group-item radius-15 contact-form-fields-div border-0 order-knaek-input-style">
                      <label>
                        {initStrings.email_private}{" "}
                        {" (" + initStrings.if_you_are_user_in_the_app + ")"}
                      </label>
                      <input
                        name="email"
                        type="email"
                        value={
                          hasAValue(orderKnaekDataRS) &&
                          orderKnaekDataRS["email"]
                            ? orderKnaekDataRS["email"]
                            : ""
                        }
                        className="form-control bg-transparent shadow-none"
                        placeholder={
                          initStrings.enter_your_private_email_address
                        }
                        required="required"
                        onChange={handleInputChange}
                      />
                    </li>
                    {hasAValue(errorMessage) &&
                      errorMessage["email"] &&
                      errorMessage["email"] && (
                        <span className={"text-danger mt-2 ml-4 font-size-12"}>
                          {initStrings.email_not_valid}
                        </span>
                      )}
                  </ul>
                </div>
              </div>

              <div className={"row mt-4"}>
                <div
                  className={
                    "col-12 d-flex justify-content-center align-items-start"
                  }
                >
                  <input
                    className={
                      "check-box-order-page rounded-circle cursor-pointer p-2"
                    }
                    type="radio"
                    checked={
                      hasAValue(orderKnaekDataRS) &&
                      orderKnaekDataRS["agreeToTerms"]
                        ? orderKnaekDataRS["agreeToTerms"]
                        : agreeToTerms
                    }
                    name="agreeToTerms"
                    onClick={(val) => {
                      handleInputChangeRadio(
                        val,
                        !agreeToTerms,
                        "agreeToTerms"
                      );
                      setAgreeToTerms(!agreeToTerms);
                    }}
                  />
                  <label
                    className={" pl-2 cursor-pointer order-page-checkbox-text"}
                    onClick={(val) => {
                      handleInputChangeRadio(
                        val,
                        !agreeToTerms,
                        "agreeToTerms"
                      );
                      setAgreeToTerms(!agreeToTerms);
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        initStrings.i_agree_to_the_terms_and_conditions_part1 + theNewYearOfKnaek() + initStrings.until_further_notice
                    }}
                  ></label>
                </div>
                {hasAValue(errorMessage) &&
                  errorMessage["agreeToTerms"] &&
                  errorMessage["agreeToTerms"] && (
                    <span className={"text-danger ml-4 pl-3 font-size-12"}>
                      {initStrings.agree_to_terms_error}
                    </span>
                  )}

                <div
                  className={
                    "col-12 d-flex justify-content-center align-items-start mt-2"
                  }
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ArrowOrderWizardComponent
        handleNext={() => handleNext()}
        handleBack={() => handleBack()}
        currentStep={currentStep}
      />
    </>
  );

  function handlePaste(event) {
    event.preventDefault(); // Prevent pasting data
  }

  async function handleInputChange(event) {
    const { name, value } = event.target;
    let updatedData = { ...orderKnaekDataRS, [name]: value };
    dispatch(updateOrderKnaekDataRS(updatedData));
  }

  function handleInputChangeRadio(event, value, name) {
    let updatedData = { ...orderKnaekDataRS, [name]: value };
    dispatch(updateOrderKnaekDataRS(updatedData));
  }

  async function handleNext() {
    setErrorMessage(null);

    if (!hasAValue(orderKnaekDataRS)) {
      setErrorMessage((errorMessage) => ({ ...errorMessage, email: true }));
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        studentEmail: true,
      }));
      setErrorMessage((errorMessage) => ({
        ...errorMessage,
        agreeToTerms: true,
      }));
      validationErrorAlertFunction(initStrings.not_all_data_are_valid);
      return;
    }

    let requiredFields = ["email", "agreeToTerms"];
    if (withStudentEmail) requiredFields.push("studentEmail");
    let isError = false;

    for (const field of requiredFields) {
      if (!hasAValue(orderKnaekDataRS[field])) {
        isError = true;
        setErrorMessage((errorMessage) => ({ ...errorMessage, [field]: true }));
      }
    }

    if (!isError) {
      const isEmailValid = await validateEmailAndSubscription(
        orderKnaekDataRS["email"]
      );
      const isStudentEmailValid = withStudentEmail
        ? await validateStudentEmailAddressAndSubscription(
            orderKnaekDataRS["studentEmail"]
          )
        : true;
      const isAgreedToTerms = orderKnaekDataRS.agreeToTerms;

      if (
        isEmailValid &&
        isStudentEmailValid &&
        isAgreedToTerms &&
        currentStep !== 3
      ) {
        setCurrentStep((currentStep) => currentStep + 1);
      } else {
        if (!isEmailValid) {
          setErrorMessage((errorMessage) => ({ ...errorMessage, email: true }));
        }
        if (!isStudentEmailValid) {
          setErrorMessage((errorMessage) => ({
            ...errorMessage,
            studentEmail: true,
          }));
        }
        if (!orderKnaekDataRS.agreeToTerms) {
          setErrorMessage((errorMessage) => ({
            ...errorMessage,
            agreeToTerms: true,
          }));
        }
        validationErrorAlertFunction(initStrings.not_all_data_are_valid);
      }
    } else {
      validationErrorAlertFunction(initStrings.not_all_data_are_valid);
    }
  }

  function handleBack() {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
  }
}
