import React from "react";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";



export default function BetweenSectionForCompanies() {

    const initStrings = {
        text1: stringsTranslate(appWords.save_precious_time_and_reach_students),
        header1: stringsTranslate(appWords.students),
        text2: stringsTranslate(appWords.each_college_year_receive_but_preferably_students_an_activation_code),
        header2: stringsTranslate(appWords.young_professionals),
        text3: stringsTranslate(appWords.with_knaek_reached_you_the_young_professionals),
        playImg: "/assets/images/studentkorting/girl.png",
    }

    return (
        <section className="section align-items-start justify-content-center mt-5 pt-5 pb-1">
            <div className="container mb-4 mt-4">
                <div className="row ">
                    {/*Text under the logo */}
                    <div className="col-12 d-flex second-section-companies">
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-10 d-flex justify-content-center align-items-center mt-lg-5 mt-md-5 mt-3 pt-3 pt-lg-5 pt-md-5">
                        <h3 className="mt-4 mb-4 px-4">{initStrings.text1}</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-10 student-knaek-between">
                        <div className={"row"}>
                            <div className="col-lg-3 col-md-3 work-icon">
                                <div className="mb-3 icon-image-companies rounded-circle text-center">
                                    <svg width="70" height="70" viewBox="0 0 32 32" className="svg-secondary">
                                        <path
                                            d="M30.12,19.29V9.54l1.27-.47a.94.94,0,0,0,0-1.76L16.33,1.62a.94.94,0,0,0-.66,0L.61,7.31a.94.94,0,0,0,0,1.76L5.69,11V17.8a.94.94,0,0,0,.59.87A26.06,26.06,0,0,0,16,20.54a26.06,26.06,0,0,0,9.72-1.87.94.94,0,0,0,.59-.87V11l1.94-.72v9a2.8,2.8,0,0,0-1.16,4.52,2.85,2.85,0,0,0-.72,1.88V29.5a.94.94,0,0,0,.94.94h3.75A.94.94,0,0,0,32,29.5V25.69a2.8,2.8,0,0,0-.72-1.88A2.78,2.78,0,0,0,32,21.94,2.8,2.8,0,0,0,30.12,19.29Zm-5.68-2.14a24.15,24.15,0,0,1-16.88,0V11.66l8.11,3a.94.94,0,0,0,.66,0l8.11-3ZM16,12.81,3.6,8.18,16,3.5,28.4,8.18ZM30.12,28.56H28.25V25.69a.94.94,0,1,1,1.87,0Zm-.93-5.68a.94.94,0,1,1,.93-.94A.95.95,0,0,1,29.19,22.88ZM16.94,8.19A.94.94,0,1,1,16,7.25.94.94,0,0,1,16.94,8.19Z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 d-flex align-items-center mb-3">
                                <h3 className={"ml-4 ml-lg-5 ml-md-5"}>{initStrings.header1}
                                    <p className={"ml-0 mt-2 p-1 line-text"}>{initStrings.text2}</p>
                                </h3>
                            </div>

                            <div className="col-lg-3 col-md-3 work-icon">
                                <div className="mb-3 icon-image-companies rounded-circle text-center">
                                    <svg width="70" height="70" viewBox="0 0 32 32" className="svg-secondary">
                                        <path
                                            d="M15.12,18.87H17a.86.86,0,0,1,.85.86.85.85,0,0,1-.85.85h-1.9a.85.85,0,0,1-.85-.85A.86.86,0,0,1,15.12,18.87Z"></path>
                                        <path
                                            d="M5.12,32H27a.85.85,0,0,0,.74-.43.86.86,0,0,0,0-.85,13.58,13.58,0,0,0-4.85-4.88,12.11,12.11,0,0,0-1.29-.67l0,0c-.28-.12-.56-.24-.85-.34l-.28-.11.27-.13a10.42,10.42,0,0,0,4.1-3.75,10.27,10.27,0,0,0,1.57-5.48V6.12A6.13,6.13,0,0,0,20.28,0h-19A.85.85,0,0,0,.47.85,6.08,6.08,0,0,0,2,4.84,6.16,6.16,0,0,0,5.62,6.9h.11v8.43a10.38,10.38,0,0,0,5.68,9.23l.27.13-.28.11c-.29.1-.57.22-.85.34l0,0a10.46,10.46,0,0,0-1.29.67,13.58,13.58,0,0,0-4.85,4.88.86.86,0,0,0,0,.85A.84.84,0,0,0,5.12,32ZM24.62,5.27h-18a4.4,4.4,0,0,1-4.3-3.4l0-.17h18a4.45,4.45,0,0,1,4.3,3.41Zm.08,4.17H7.44V7H24.7Zm-9.57,1.7-.05.17a3.89,3.89,0,0,1-7.51,0l0-.17Zm9.48,0-.05.17a3.88,3.88,0,0,1-7.5,0l0-.17ZM18.13,30.3,21.44,27l.09.05a11.84,11.84,0,0,1,3.73,3l.17.22Zm-1.21-4.59h.14a12.36,12.36,0,0,1,2.48.48l.2.07-2.82,2.82ZM16.07,24a8.65,8.65,0,0,1-8.34-6.39,9.92,9.92,0,0,1-.29-2V14.32l.22.19A5.59,5.59,0,0,0,16,13.42l.11-.16.11.16a5.59,5.59,0,0,0,8.3,1.09l.22-.19v1.22a5.48,5.48,0,0,1-.3,2A8.63,8.63,0,0,1,16.07,24Zm-.85,5.12L12.4,26.27l.2-.07a12.4,12.4,0,0,1,2.47-.48h.15ZM6.71,30.3l.17-.22a11.73,11.73,0,0,1,3.73-3L10.7,27,14,30.3Z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 d-flex align-items-center ">
                                <h3 className={"ml-4 ml-lg-5 ml-md-5"}>{initStrings.header2}
                                    <p className={"ml-0 mt-2 p-1 line-text"}>{initStrings.text3}</p></h3>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}
