import React from 'react';

export default function KnaekSecondaryButtonWithSearchIcon({title, ...props}) {

    return (
        <button {...props}>
            <svg width="16" height="16" viewBox="0 0 32 32" className="svg-search-icon mr-2">
                <path
                    d="M31.22,27.44l-6.95-7-.12-.09a13.14,13.14,0,1,0-3.76,3.76l.09.11,7,7a2.68,2.68,0,0,0,3.79-3.78ZM13.16,21.75a8.6,8.6,0,1,1,8.59-8.59A8.6,8.6,0,0,1,13.16,21.75Z"></path>
            </svg>
            {title}</button>
    );
}
