import React, {useState} from "react";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";




export default function PartnerText() {
    const [showElement1, setShowElement1] = useState(false)
    const [showElement2, setShowElement2] = useState(false)

    const initStrings = {
        heading: stringsTranslate(appWords.our_partners),
        headingText: stringsTranslate(appWords.our_partners_heading_text),
        contentText: stringsTranslate(appWords.our_partners_content_text),
        contentText1: stringsTranslate(appWords.our_partners_content_text_1),

        cardContentOne:stringsTranslate(appWords.our_partners_card_content_one),
        cardContentOneExtra1:stringsTranslate(appWords.our_partners_card_content_one_extra1),
        cardContentOneExtra2:stringsTranslate(appWords.our_partners_card_content_one_extra2),
        cardContentOneExtra3:stringsTranslate(appWords.our_partners_card_content_one_extra3),

        cardContentTwo:stringsTranslate(appWords.our_partners_card_content_two),
        cardContentTwoExtra1:stringsTranslate(appWords.our_partners_card_content_two_extra1),
        cardContentTwoExtra2:stringsTranslate(appWords.our_partners_card_content_two_extra2),
      }

    return (
        <div id="screenshots" className="section mt-5 pt-4 partner-section w-100 mb-5">
            <div className="container">
                <div className=" row">
                    <div className="col-lg-12 text-md-left mb-5 text-lg-left text-center align-content-center">
                        <h1 className="">{initStrings.heading}</h1>
                        {/*<p className="mt-2 mb-4">{initStrings.headingText}</p>*/}
                        <p className="mt-2 mb-4">{initStrings.contentText}</p>
                        {/*<p className="mt-3">{initStrings.contentText1}</p>*/}
                    </div>
                </div>
                <div className="row align-items-center justify-content-center mt-5 align-items-center">
                    {/* Section Heading */}
                    <div className="col-12 d-flex flex-wrap justify-content-center ">
                        <div className={"col-lg-6 text-center mb-3"}>
                                <div className={"col-12 card shadow w-100 radius-15 cursor-pointer"}>
                                    <div className={"card-body"} onClick={()=>setShowElement1(!showElement1)}>
                                        <div className={"col-12 text-center"} >
                                            <h4>{initStrings.cardContentOne}</h4>
                                        </div>
                                        <img src={"../assets/images/Frame 15(1).png"} alt={""}/>
                                        {showElement1 && <div className={"col-12 text-left text-justify"}>
                                            <br/>
                                            <p>{initStrings.cardContentOneExtra1}</p>
                                            <br/>
                                            <p>{initStrings.cardContentOneExtra2}</p>
                                            <br/>
                                            <p>{initStrings.cardContentOneExtra3}</p>
                                            <br/>

                                        </div>}
                                    </div>
                                </div>
                            </div>
                        <div className={"col-lg-6 text-center"}>
                                <div className={"col-12 card shadow w-100 radius-15 cursor-pointer"}>
                                    <div className={"card-body"} onClick={()=>setShowElement2(!showElement2)}>
                                        <div className={"col-12 text-center"} >
                                            <h4>{initStrings.cardContentTwo}</h4>
                                        </div>
                                        <img src={"../assets/images/Frame 15.png"} alt={""}/>
                                        {showElement2 && <div className={"col-12 text-left text-justify"}>
                                            <br/>
                                            <p>{initStrings.cardContentTwoExtra1}</p>
                                            <br/>
                                            <p>{initStrings.cardContentTwoExtra2}</p>
                                            <br/>

                                        </div>}

                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
