import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import FooterSection from "../../../components/FooterSection/Footer";
import SnipTechDiscountDetailFirstSectionOnlineDiscounts
    from "../../../components/AppSections/SniptechOnlineDiscountsPageSections/SniptechDiscountDetailFirstSectionOnlineDiscounts";
import { getSnipTechStoreByIdCall } from "../../../api/ApiCalls";
import RedirectToAppModalComponent from "../../../components/AppComponents/RedirectToAppModalComponent";
import {appUrls} from "../../../config/Constants";
import {Helmet} from "react-helmet";
import {appWords} from "../../../config/Constants";
import {stringsTranslate} from "../../../locales/CustomTranslater";

export default function SnipTechOnlineDiscountInfo() {
    const {id} = useParams();
    const [snipTechOnlineDiscount, setSnipTechOnlineDiscount] = useState(undefined)
    const [deals, setDeals] = useState([])
    const [showRedirectAppModal, setShowRedirectAppModal] = useState(false);
    const online_discounts_cm = stringsTranslate(appWords.online_discounts_cm);


    useEffect(() => {
        getAllSnipTechOnlineDealsDiscountsById(id);  // Pass the id here
    }, [id]); // Added snipTechOnlineDiscountsRS to dependency array since it's being used inside the effect

    useEffect(() => {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            // window.location = 'knaek://Homepage?online=true&id=' + id
            setShowRedirectAppModal(true)

        }
    }, [id])

    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {online_discounts_cm}</title>
            </Helmet>
            <div className="main">
                <RedirectToAppModalComponent showModal={showRedirectAppModal} closeModal={()=> setShowRedirectAppModal(!showRedirectAppModal)} storeDiscount={snipTechOnlineDiscount}
                                             storeID={id} link={'knaek://Homepage?online=true&id='} online={true} icon={snipTechOnlineDiscount?.logo} title={snipTechOnlineDiscount?.title}/>
                <SnipTechDiscountDetailFirstSectionOnlineDiscounts snipTechOnlineDiscount={snipTechOnlineDiscount} deals={deals}/>
                <FooterSection />
            </div>
        </div>
    );

    async function getAllSnipTechOnlineDealsDiscountsById(id) {
        try {
            // Fetching store and deal ID
            const storeResponse = await getSnipTechStoreByIdCall(id);
            setSnipTechOnlineDiscount(storeResponse);
            setDeals(storeResponse?.deals);
        } catch (e) {
            console.log(e);
        }
    }
}
