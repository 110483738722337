import React from "react";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";





export default function KnaekVacancys() {

    const initData = {
        working_at_knaek: stringsTranslate(appWords.working_at_knaek),
        come_work_at_knaek: stringsTranslate(appWords.come_work_at_knaek),
        the_nicest_at_job_for_students: stringsTranslate(appWords.the_nicest_at_job_for_students),
        become_part_of_our_enthusiastic_team_and_discover: stringsTranslate(appWords.become_part_of_our_enthusiastic_team_and_discover),
        view_below_the_available: stringsTranslate(appWords.view_below_the_available),
        link: "info@knaek.nl",
        content3: stringsTranslate(appWords.who_knows_make_you_soon),

        image: "/assets/images/vacatures.png"
    }

    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className=" col-lg-6 col-md-12">
                            {/* Welcome Thumb */}
                            <div className="d-flex">
                                <img src={initData.image} alt=""/>
                            </div>
                        </div>
                        {/* Welcome Intro Start */}
                        <div className="col-lg-6 col-md-12">
                            <div className="vacatures">
                                <h1 className={"mx-4"}>{initData.working_at_knaek}</h1>
                                <p className="mt-4 mx-4" dangerouslySetInnerHTML={{__html: initData.come_work_at_knaek}}>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
