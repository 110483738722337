import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getActionDetailsCall, getPaymentCouponsCall, loginWithOneTimeTokenCall} from "../../../api/ApiCalls";
import {updateAuthDataNEWRS} from "../../../redux/reduxActions/reduxStoreActions";
import {appWords} from "../../../config/Constants";
import CouponDetailAndPurchaseSection
    from "../../../components/AppSections/WebshopSections/CouponDetailAndPurchaseSection";
import {tokenErrorAlertFunction} from "../../../uitls/AlertFunctions";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {failedAlertFunction, successAlertFunction} from "../../../uitls/AlertFunctions";
import {dateFormat, hasAValue} from "../../../uitls/SharedFunctions";
import { useHandleLinkClick } from '../../../hooks/useHandleLinkClick';
import {Helmet} from "react-helmet";

export default function CouponInfoAndPurchase() {

    let initStrings = {
        link_expired: stringsTranslate(appWords.link_expired),
        the_payment_failed: stringsTranslate(appWords.the_payment_failed),
        your_voucher_code: stringsTranslate(appWords.your_voucher_code),
        until: stringsTranslate(appWords.until),
        valid_from: stringsTranslate(appWords.valid_from),
    }


    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const dispatch = useDispatch()
    const {payment_id, id} = useParams()
    let contentLanguage = appLanguageRS === "en"? "en": "nl"
    const history = useHistory();
    const location = useLocation();
    const [completedPurchase, setCompletedPurchase] = useState(false);

    const [webshopAction, setWebshopAction] = useState(null);
    const [isRedirect, setIsRedirect] = useState(false);
    const handleLinkClick = useHandleLinkClick();

    let language = appLanguageRS
    // const intiStrings = {}

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token) {
            oneTimeTokenLogin(token, params)
        }
        if(payment_id){
            getPaymentCoupons(payment_id, id)
        }
    }, [])

    useEffect(() => {
        getActionDetails(id);  // Pass the id here
    }, [id]);

    useEffect(() => {
        if(isRedirect && hasAValue(webshopAction) )
            handleLinkClick("/studentenkorting/Amesterdam/"+ encodeURIComponent(webshopAction.localization[contentLanguage].subtitle) + '/' + webshopAction.id);
    }, [webshopAction, isRedirect]);

    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>{`Knaek - ${webshopAction?.company?.name} - ${webshopAction?.localization[contentLanguage]?.subtitle}`}</title>
            </Helmet>
            <div className="main">
                    <>
                        <CouponDetailAndPurchaseSection webshopAction={webshopAction} completedPurchase={completedPurchase} showUserCoupons={showUserCoupons} />
                    </>
            </div>
        </div>
    );
    async function oneTimeTokenLogin(token, params) {
        function invalidLinkFeedback() {
            tokenErrorAlertFunction(initStrings.link_expired, "")
            setTimeout(() => {
                // window.location.href = "/"
            }, 3000);
        }

        try {
            const response = await loginWithOneTimeTokenCall({token});
            if (!response) {
                invalidLinkFeedback();
                return;
            }
            dispatch(updateAuthDataNEWRS({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                city_id: response.data.city_id,
            }));

            if (params.has('token')) {
                params.delete('token');
                history.replace({ search: params.toString() });
            }

            setTimeout(() => {
                if (response.data?.goal?.toString().includes("APP_TO_WEB_REDIRECTION")) {
                    // window.location.href = "/account/online-bespaard#openClaim"
                } else {
                    // window.location.href = "/"
                }
            }, 500);

        } catch (e) {
            invalidLinkFeedback();
        }
    }

    async function getActionDetails(id) {
        try {
            const data = await getActionDetailsCall(id);
            if(data.type === 'COUPONS')
                handleLinkClick("/studentenkorting/Amesterdam/"+ encodeURIComponent(data.localization[contentLanguage].subtitle) + '/' + data.id);
            setWebshopAction(data);
        } catch (e) {
            console.log(e);
        }
    }

    function redirectToDiscountPage()
    {
        setIsRedirect(true)
    }

    async function showUserCoupons(vouchers) {
        if(vouchers.length > 0)
        {
            setCompletedPurchase(true)
            let vouchersMessage = ''
            vouchers.forEach(voucher => {
                vouchersMessage += '<p class="action-voucher-code">' + initStrings.your_voucher_code + ': <b><span >' + voucher.voucher + '</span></b></p>'
                vouchersMessage += '<p class="action-voucher-date">(' + initStrings.valid_from + ' ' + dateFormat(voucher.startDate) + ' ' + initStrings.until + ' ' + dateFormat(voucher.endDate) + ')</p>'
            });
            successAlertFunction(vouchersMessage , '', redirectToDiscountPage)

        }
    }

    async function getPaymentCoupons(payment_id, discount_id) {
        try {
            const response = await getPaymentCouponsCall(payment_id, discount_id);
            showUserCoupons(response.data);
        } catch (e) {
            failedAlertFunction(initStrings.the_payment_failed, '')
        }
    }



}
