import React, {useEffect, useState} from 'react';
import SimilarDiscountsSliderStore from "../../AppCustomizedComponent/SimilarDiscountsSliderStore";
import {useSelector} from "react-redux";
import {hasAValue} from "../../../uitls/SharedFunctions";
import SliderOnlineDiscounts from "../../AppCustomizedComponent/SliderOnlineDiscounts";




const initStrings = {
    heading: "Vergelijkbare kortingen",
    headingText: "We hebben de beste kortingen van het moment speciaal voor jou uitgelicht. ",
}


export default function SimilarStoreDiscounts({storeDiscount}) {
    const storeDiscountsRS = useSelector(state => state.storeDiscountsRS);
    const [similarDiscounts, setSimilarDiscounts] = useState([])


    useEffect(() => {
        if (hasAValue(storeDiscount)) {
            let elements = storeDiscountsRS.filter(item => (parseInt(item.categoryId) === parseInt(storeDiscount.categoryId)))
            setSimilarDiscounts(elements)
        }
    }, [storeDiscount])


    return (
        <div id="screenshots" className="section bg-gray homepage-online-section mt-5 py-5">
            <div className="container-fluid ">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 pt-5 mt-5 mb-5">
                        <section className="row justify-content-center justify-content-lg-start">
                            {/* Section Heading */}
                            <div className="col-12 text-center">
                                <h2 className="">{initStrings.heading}</h2>
                                <h5 className="mt-4">{initStrings.headingText}</h5>
                            </div>
                        </section>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-8 my-3">
                        <div className="row">
                            <SimilarDiscountsSliderStore similarDiscounts={similarDiscounts}/>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    );
}
