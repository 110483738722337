import React, {Component} from 'react';
import KnaekSecondaryButton from "../AppComponents/KnaekSecondaryButton";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {Helmet} from "react-helmet";



class ErrorPage extends Component {

    render() {

        const initData = {

        heading: "404",
        text: stringsTranslate(appWords.the_page_you_are_looking_for_might),
        btnText: stringsTranslate(appWords.go_to_homepage)
    }
        return (
            <div className="inner inner-pages">
                <Helmet>
                    <title>Knaek - 404</title>
                </Helmet>
                <div className="main">
                <section id="home" className="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
                    <div className="container h-100">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-12 col-md-7">
                                <div className="welcome-intro error-area text-center">
                                    <h1 className="text-white">{initData.heading}
                                        <p className="text-white my-4">{initData.text}</p>
                                    </h1>
                                    <div className="pb-3">
                                        <KnaekSecondaryButton
                                            props={""}
                                            onClick={() => {
                                                window.location.href = "/"
                                            }}
                                            title={initData.btnText.toString().toUpperCase()}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
            </div>
        );
    }
}

export default ErrorPage;