import React, {useEffect, useState} from 'react';
export default function ArrowOrderWizardComponent({handleBack, handleNext, currentStep,zIndex=0}) {
    const [progressPercentage, setProgressPercentage] = useState(25);


    useEffect(() => {
        setProgressPercentage(currentStep * 33.33333333333333)
    }, [currentStep])

    return (
        <div className="col-12 d-flex justify-content-center align-content-center align-items-center fixed-bottom-navigator">
            <div className="row justify-content-between w-100 align-items-center my-5">
                    <div className="col-3 text-left">
                        <a className="bg-transparent"
                           onClick={() => handleBack()}>
                            <span className="fa fa-arrow-left slider-arrow slider-button-arrow"/>
                        </a>
                    </div>
                <div className="col-6">
                    {/*<span>1</span>*/}
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width: progressPercentage+'%', backgroundColor: 'var(--primary)'}} aria-valuenow="25"
                             aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    {/*<span>4</span>*/}
                </div>
                    <div className="col-3 text-right">
                        <a className="bg-transparent "
                           onClick={() => handleNext()}>
                            <span className="fa fa-arrow-right slider-arrow slider-button-arrow"/>
                        </a>
                    </div>
            </div>
        </div>

    );

}
