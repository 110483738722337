import React, {useState} from 'react';

const ReviewStarRatingIcon = ({ index, getFillColor, handleMouseOver, handleMouseOut, handleClick }) => {
    return (
        <g transform={`translate(${index * 100}, 0)`}>
            <a onMouseOver={() => handleMouseOver(index)} onMouseOut={handleMouseOut} onClick={() => handleClick(index)}>
                <rect className="Rectangle-path" fill={getFillColor(index)} x="0" y="0" width="96" height="96"/>
                <path
                    d="M82.0071 40.3079L56.0633 40.3079L48.0883 15.8783L40.1134 40.3079L14.1696 40.3079L35.1669 55.4502L27.1919 79.8798L48.1893 64.7375L69.7917 80.1217L61.3126 55.2573L82.0071 40.3079Z"
                    fill="#ffffff" fillRule="nonzero" opacity="1" stroke="none"/>
            </a>
        </g>
    );
};

const StarArray = ({onRatingChange}) => {
    const [hoverIndex, setHoverIndex] = useState(null);
    const [clickIndex, setClickIndex] = useState(null);

    const handleMouseOver = index => setHoverIndex(index);
    const handleMouseOut = () => setHoverIndex(null);
    const handleClick = index => {
        setClickIndex(index);
        onRatingChange(index + 1);
    };

    const getFillColor = (index) => {
        if (clickIndex !== null && index <= clickIndex) {
            return getHoverColor(clickIndex);
        }
        if (hoverIndex !== null && index <= hoverIndex) {
            return getHoverColor(hoverIndex);
        }
        return "#dcdce5";
    };

    const getHoverColor = (index) => {
        switch (index) {
            case 0: return '#ce1b1b';
            case 1: return '#ce1b1b';
            case 2: return '#e3d10a';
            case 3: return '#01de91';
            case 4: return '#01de91';
            default: return '';
        }
    };

    const starItems = Array.from({ length: 5 }, (_, index) => (
        <ReviewStarRatingIcon
            key={index}
            index={index}
            getFillColor={getFillColor}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
            handleClick={handleClick}
        />
    ));

    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 512 96" version="1.1">
            <g id="5-star">
                <g opacity="1">
                    {starItems}
                </g>
            </g>
        </svg>
    );
};

export default StarArray;