import React, { useEffect, useState } from "react";
import MyRouts from "./routers/routes";
import Header from "./components/HeaderSection/Header";
import { useDispatch, useSelector } from "react-redux";
import { updateLanguageRS } from "./redux/reduxActions/reduxStoreActions";
import i18n from "i18n-js";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import { useHistory, BrowserRouter as Router } from "react-router-dom";
import LoginComponent from "./components/AppComponents/LoginComponent";
import ReactGA from "react-ga4";
import LoginLinkComponent from "./components/AppComponents/LoginLinkComponent";
import SMPixelNotificationComponent from "./components/AppComponents/SMPixelNotificationComponent";
import Loader from "./components/Loader/Loader";
import HostnameRedirect from "./routers/HostnameRedirect";

export default function App() {
  const pixelId = process.env.REACT_APP_META_PIXEL_ID; // replace with your Pixel ID
  const tiktokPixelId = process.env.REACT_APP_TIKTOK_PIXEL_ID; // replace with your Pixel ID
  const googleTagForComDomain = process.env.REACT_APP_GA4_ID_COM;
  const googleTagForNlDomain = process.env.REACT_APP_GA4_ID_NL;
  const currentDomain = window.location.hostname;
  const [isLoading, setIsLoading] = useState(true);

  const [timer, setTimer] = useState(() => {
    const storedTimer = parseInt(localStorage.getItem('timer'), 10);
    return !isNaN(storedTimer) ? storedTimer : 1 * 60;
  });

  const dispatch = useDispatch();

  let appLanguageRS = useSelector((state) => state.appLanguageRS);

  i18n.locale = appLanguageRS;

  useEffect(() => {
    ReactPixel.init(pixelId);
    ReactPixel.pageView();
    TiktokPixel.init(tiktokPixelId);
    TiktokPixel.pageView();
    const googleTagId = currentDomain.includes(".nl")
      ? googleTagForNlDomain
      : googleTagForComDomain;
    ReactGA.initialize(googleTagId);
  }, []);

  useEffect(() => {
    if (!appLanguageRS) {
      const baseDomain = window.location.hostname.toLowerCase();

      if (baseDomain.includes("knaek.com")) {
        i18n.locale = "en";
        dispatch(updateLanguageRS("en"));
      } else if (baseDomain.includes("knaek.nl")) {
        i18n.locale = "nl";
        dispatch(updateLanguageRS("nl"));
      } else {
        i18n.locale = "nl";
        dispatch(updateLanguageRS("nl"));
      }
    } else {
      i18n.locale = appLanguageRS;
    }
  }, [appLanguageRS, dispatch]);

  const emailInModalRS = useSelector((state) => state.emailInModalRS);

  useEffect(() => {
    let interval;
    if (emailInModalRS) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
    } else {
      // Clear the timer interval when the modal is closed
       clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [emailInModalRS]);

  // Update localStorage with timer value whenever it changes
  useEffect(() => {
    localStorage.setItem('timer', timer);
  }, [timer]);

  useEffect(() => {
    setTimeout(function(){
        setIsLoading(false);
    },1000)
  }, []);

  return (
    <div>
      { isLoading &&
        <Loader />
      }
      <HostnameRedirect />
      <Router>
        <Header
          switchLanguage={(language) => switchLanguage(language)}
          imageData={"img/knaek/logo.png"}
        />
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        <MyRouts />
        <LoginComponent />
      </Router>
      <LoginLinkComponent timer={timer} setTimer={setTimer} />
      <SMPixelNotificationComponent timer={timer} setTimer={setTimer} />
    </div>
  );

  function switchLanguage(language) {
    document.querySelector('.navbar').classList.remove('active')
    document.querySelector('body').classList.remove('canvas-open')
    document.querySelector('.navbar-toggler-icon').classList.remove('active')
    dispatch(updateLanguageRS(language));
  }
}
