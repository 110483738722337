import React, {useEffect, useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import {
    checkAvailabilityValidityDataCall,
    createMembershipCancellationCall, findSubscriptionByIbanBirthdayCall
} from "../../../api/ApiCalls";
import {
    validateEmail
} from "../../../uitls/DataValidator";
import {validationErrorAlertFunction} from "../../../uitls/AlertFunctions";
import {hasAValue} from "../../../uitls/SharedFunctions";
import ArrowUnsubscribeWizardComponent from "./ArrowUnsubscribeWizardComponent";
import {Animated} from "react-animated-css";


export default function CancellingMembershipFlowStep1({currentStep, setCurrentStep, setHasSubscription,
                                                          email, setEmail, birthday, iban, setBirthday, setIban, phone_number, setPhone_number}) {

    const [cancellation_date, setCancellation_date] = useState(null);
    const [cancellation_email, setCancellation_email] = useState(null);
    const [isCancelled, setIsCancelled] = useState(false);
    const [currentSubStep, setCurrentSubStep] = useState(1);
    const [errorMessageTitle, setErrorMessageTitle] = useState('');
    const [errorMessageBody, setErrorMessageBody] = useState('');



    const initStrings = {
        unsubscription_knaek_id: stringsTranslate(appWords.unsubscription_knaek_id),
        in_what_lang: stringsTranslate(appWords.in_what_lang),
        we_are_going_to_ask: stringsTranslate(appWords.we_are_going_to_ask),
        our_first_question: stringsTranslate(appWords.our_first_question),
        your_email_address: stringsTranslate(appWords.your_email_address),
        placeholder2: stringsTranslate(appWords.placeholder_text_your_email),
        select_option: stringsTranslate(appWords.select_option),
        not_all_data_are_valid: stringsTranslate(appWords.not_all_data_are_valid),
        reason: stringsTranslate(appWords.reason),
        comment: stringsTranslate(appWords.comment),
        the_membership_that_comes_with: stringsTranslate(appWords.the_membership_that_comes_with),
        has_already_been_canceled_on: stringsTranslate(appWords.has_already_been_canceled_on),
        membership_is_already_cancelled: stringsTranslate(appWords.membership_is_already_cancelled),
        we_are_sorry_to: stringsTranslate(appWords.we_are_sorry_to),
        lets_match_with_phone_number: stringsTranslate(appWords.lets_match_with_phone_number),
        we_are_sorry_to_again: stringsTranslate(appWords.we_are_sorry_to_again),
        lets_match_with_iban_and_birthday: stringsTranslate(appWords.lets_match_with_iban_and_birthday),
        iban: stringsTranslate(appWords.iban),
        last_6_iban: stringsTranslate(appWords.last_6_iban),
        birthday: stringsTranslate(appWords.birthday),
        full_name: stringsTranslate(appWords.full_name),
        phone_number_label: stringsTranslate(appWords.phone_number_label),
        phone_number_error_format: stringsTranslate(appWords.phone_number_error_format),
        over_limit_error_message: stringsTranslate(appWords.over_limit_error_message),
        no_match_data_unsubscribe: stringsTranslate(appWords.no_match_data_unsubscribe),
        no_match_data_unsubscribe_title: stringsTranslate(appWords.no_match_data_unsubscribe_title),
        youHaveReachedTheMaxCancellation: stringsTranslate(appWords.youHaveReachedTheMaxCancellation),


    }

    return (
        <section className="section align-items-center justify-content-center mt-5">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12 bg-white">

                        {!errorMessageTitle && <div className={"text-left mt-5 mb-3 bg-gray p-5 radius-25"}>
                            { (!isCancelled && currentSubStep === 1) &&  <h1 className="my-3">{initStrings.unsubscription_knaek_id}</h1>}
                            { (!isCancelled && currentSubStep === 1) &&  <p className={"mt-4 mb-3"}>{initStrings.in_what_lang}</p>}
                            { (!isCancelled && currentSubStep === 2) &&  <h1 className="my-3">{initStrings.we_are_sorry_to}</h1>}
                            { (!isCancelled && currentSubStep === 2) &&  <p className={"mt-4 mb-3"}>{initStrings.lets_match_with_phone_number}</p>}
                            { (!isCancelled && currentSubStep === 3) &&  <h1 className="my-3">{initStrings.we_are_sorry_to_again}</h1>}
                            { (!isCancelled && currentSubStep === 3) &&  <p className={"mt-4 mb-3"}>{initStrings.lets_match_with_iban_and_birthday}</p>}
                            { isCancelled && <h1 className={"my-3"}>{initStrings.membership_is_already_cancelled}</h1>}
                            { isCancelled && <p className={"mt-4 mb-3"}>{initStrings.the_membership_that_comes_with +" "+ cancellation_email+" "+ initStrings.has_already_been_canceled_on +" "+ cancellation_date+"."}</p>}

                            {currentSubStep === 1 && <li className="list-group-item radius-15 contact-form-fields-div border-0 my-4">
                                <label>{initStrings.your_email_address}</label>
                                <input name="email" type="email"
                                       className="form-control bg-transparent shadow-none"
                                       placeholder={initStrings.placeholder2}
                                    onChange={(val) => {
                                        setEmail(val.target.value)
                                    setIsCancelled(false)
                                    }}
                                />
                            </li>}

                            {currentSubStep === 2 &&
                                <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                    <label>{initStrings.phone_number_label}</label>
                                    <input name="name" type="text"
                                           defaultValue={phone_number}
                                           value={phone_number}
                                           className="form-control bg-transparent shadow-none"
                                           placeholder={initStrings.phone_number_label}
                                           onChange={(val) => {
                                               const formattedPhoneNumber = val.target.value.replace(/^0/, '');
                                               setPhone_number(formattedPhoneNumber)
                                               setIsCancelled(false)
                                           }}
                                    />
                                </li>}

                            {currentSubStep === 3 &&
                                <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                    <label>{initStrings.last_6_iban}</label>
                                    <input name="iban" type="text"
                                           defaultValue={iban}
                                           maxLength={6}
                                           minLength={6}
                                           className="form-control bg-transparent shadow-none"
                                           placeholder={initStrings.last_6_iban}
                                           onChange={(val) => {
                                               setIban(val.target.value)
                                               setIsCancelled(false)
                                           }}
                                    />
                                </li>}

                            {currentSubStep === 3 &&
                                <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                    <label>{initStrings.birthday}</label>
                                    <input name="birthday" type="date"
                                           defaultValue={birthday}
                                           className="form-control bg-transparent shadow-none"
                                           placeholder={initStrings.birthday}
                                           onChange={(val) => {
                                               setBirthday(val.target.value)
                                               setIsCancelled(false)
                                           }}
                                    />
                                </li>}
                            <ArrowUnsubscribeWizardComponent handleNext={() => handleNext()} handleBack={() => handleBack()}
                                                       currentStep={currentStep}/>

                        </div>}
                        {errorMessageTitle && <div className='row'>
                            <Animated className={"col-12 bg-white pt-2 mt-2 d-flex justify-content-center align-items-center flex-wrap flex-column"}
                                      animationIn="bounceInLeft"
                                      animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                                      animationInDelay={100}>

                                <h2 className="text-knaek-title mt-5 text-start ">{errorMessageTitle}</h2>
                                <p style={{ whiteSpace: 'pre-line' }} className="text-knaek-primary mt-2 text-start pre-line ">{errorMessageBody}</p>
                            </Animated>
                        </div>}
                    </div>

                </div>
            </div>
        </section>
    );


    async function handleNext() {

        try {
            if(currentSubStep === 1){
                if(!validateEmail(email)) {
                    validationErrorAlertFunction(initStrings.phone_number_error_format)
                    return;
                }
                let response = await checkAvailabilityValidityDataCall("email", email);

                if (response.hasSubscription && response.cancelled) {
                    setIsCancelled(response.cancelled)
                    setCancellation_email(response?.email)
                    let cancellation_date = new Date(response.cancellation_date)
                    setCancellation_date(cancellation_date.toLocaleDateString("nl"))

                }else if(!response.hasSubscription){
                    setCurrentSubStep(2)

                } else if(response.hasSubscription && !response.cancelled) {
                    setHasSubscription(response.hasSubscription)
                    await createMembershipCancellationCall({email, phone_number, iban, birthday})
                    if (currentStep !== 2) {
                        setCurrentStep(currentStep + 1);
                    }
                }
            }

            if(currentSubStep === 2){
                console.log(phone_number.toString().length)
                if(!hasAValue(phone_number) || phone_number.toString().length < 8) {
                    validationErrorAlertFunction(initStrings.not_all_data_are_valid)
                    return;
                }
                let response = await checkAvailabilityValidityDataCall("phone_number", phone_number);
                setCancellation_email(response?.email)

                if (response.hasSubscription && response.cancelled) {
                    setIsCancelled(response.cancelled)
                    let cancellation_date = new Date(response.cancellation_date)
                    setCancellation_date(cancellation_date.toLocaleDateString("nl"))

                }else if(!response.hasSubscription){
                    setCurrentSubStep(3)

                } else if(response.hasSubscription && !response.cancelled) {
                    setHasSubscription(response.hasSubscription)
                    await createMembershipCancellationCall({email: response.email, phone_number, iban, birthday})
                    if (currentStep !== 2) {
                        setCurrentStep(currentStep + 1);
                    }
                }
            }

            if(currentSubStep === 3){
                if(!hasAValue(birthday) || !hasAValue(iban) || iban.toString().length < 6) {
                    validationErrorAlertFunction(initStrings.not_all_data_are_valid)
                    return;
                }
                let data = {birthday, last6Iban: iban}
                let response = await findSubscriptionByIbanBirthdayCall(data);
                setCancellation_email(response?.email)
                setHasSubscription(response.hasSubscription)
                if(!response.hasSubscription){
                    await createMembershipCancellationCall({email, phone_number, iban, birthday})

                    if (currentStep !== 2) {
                        setCurrentStep(currentStep + 1);
                    }

                } else if (response.hasSubscription && response.cancelled) {

                    setIsCancelled(response.cancelled)
                    let cancellation_date = new Date(response.cancellation_date)
                    setCancellation_date(cancellation_date.toLocaleDateString("nl"))

                } else if(response.hasSubscription && !response.cancelled) {
                    await createMembershipCancellationCall({email: response.email , phone_number, iban, birthday})
                    setHasSubscription(response.hasSubscription)
                    if (currentStep !== 2) {
                        setCurrentStep(currentStep + 1);
                    }
                }
            }



        } catch (error) {
            console.error("An error occurred:", error?.response?.data?.message);
            if(error?.response?.data?.message?.toString().includes("maximum number"))  {
                setErrorMessageTitle(initStrings.youHaveReachedTheMaxCancellation)
            }else if(error?.response?.data?.message?.toString().includes("An error occurred"))  {
                setErrorMessageTitle(initStrings.no_match_data_unsubscribe_title)
                setErrorMessageBody(initStrings.no_match_data_unsubscribe)
            }else{
                setErrorMessageTitle("Something went wrong, please contact us")
            }
        }

    }


    function handleBack() {
        if (currentStep !== 1)
            setCurrentStep(currentStep - 1)
    }
}
