import React, {useRef} from 'react';
import Slider from "react-slick";
import HomeSnipTechOnlineDiscountCard from "../AppComponents/HomeSniptechOnlineDiscountCard";


const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    infinite: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },

    ]

};
export default function SliderSniptechOnlineDiscounts({data}) {
    const sliderRef = useRef(null);
    return (
        <div className="col-12 justify-content-center w-100">
            {/* App Screenshot Slider Area */}
            <Slider {...settings} ref={sliderRef} className={""}>
                {/* Single Screenshot Item */}
                {data.slice(0, 20).map((item, i) => {
                    return(
                        <div className="homepage-online-section-online-card d-flex col-12 w-100" key={i}>
                            <HomeSnipTechOnlineDiscountCard item={item} />
                        </div>
                    )}
                )}
            </Slider>
            <div className="col-12 d-flex justify-content-center mr-lg-5 pr-lg-5 mt-lg-4 mb-5 ">
                <a className={"bg-transparent slider-button-arrow pr-5"} onClick={() => handleback()}><span
                    className={"fa fa-arrow-left slider-arrow"}/></a>

                <a className={"bg-transparent slider-button-arrow  pl-lg-5"} onClick={() => handleNext()}><span
                    className={"fa fa-arrow-right slider-arrow"}/></a>
            </div>
        </div>
    );

    function handleNext() {
        sliderRef.current.slickNext();
    };

    function handleback() {
        sliderRef.current.slickPrev();
    };
}
