import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import routeMapping from '../routers/routeMapping';
import { useSelector } from 'react-redux';
 
const useRouteInterceptor = () => {
//  if we want to change lang based on app local

  let appLanguageRS = useSelector((state) => state.appLanguageRS);
//  if we want to change lang based on .com
  const getLanguageFromHostname = () => {
    const hostname = window.location.hostname;
    if (hostname.endsWith('.com')) {
      return 'en';
    } else if (hostname.endsWith('.nl')) {
      return 'nl';
    }
    return 'en';  
  };

  const language = appLanguageRS;
 
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const mapRoutes = routeMapping[language];
    const currentPath = location.pathname;
 
    const segments = currentPath.split('/').filter(Boolean);
 
    const translatedSegments = segments.map(segment => mapRoutes[segment] || segment);
 
    const translatedPath = '/' + translatedSegments.join('/');

    if (translatedPath !== currentPath) {
      history.replace(translatedPath);
    }
  }, [location, language, history]);
};

export default useRouteInterceptor;
