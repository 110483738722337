import {isValidPhoneNumber} from 'react-phone-number-input'
import {hasAValue} from "./SharedFunctions";
import {
    checkAvailabilityValidityDataCall,
    verifyPhoneNumberCheckNoLoginCall,
    verifyPhoneNumberSendCall
} from "../api/ApiCalls";

// Validate email format
export function validateEmail(email) {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
}

export async function validateEmailAndSubscription(email) {
    try {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(email)) {
            let response = await checkAvailabilityValidityDataCall("email", email);
            return !response.hasSubscription;
        } else {
            return false;
        }
    } catch (error) {
        // Handle the error here
        console.error('An error occurred during email validation:', error);
        return false;
    }
}


export async function validateStudentEmailAddressAndSubscription(email) {
    try {
        let emailValid = await validateEmailAndSubscription(email);
        if (emailValid) {
            let response = await checkAvailabilityValidityDataCall("student_email_address", email);
            return response.is_available;
        } else {
            return false;
        }
    } catch (error) {
        // Handle the error here
        console.error('An error occurred during email address validation:', error);
        return false;
    }
}

export function validateName(value) {
    const minLength = 3;
    return value.length >= minLength;
}


export function validatePassword(password) {
    // Password should have at least 8 characters
    const minLength = 3;
    return password.length >= minLength;
}

export function validatePasswordHard(password) {
    // Password should have at least 8 characters
    hasAValue(password)
    const minLength = 8;
    return  hasAValue(password) && password.length >= minLength;
}

export function validatePhoneNumber(country_code, phone_number) {
    let complete_phone_number = country_code+phone_number
    return  isValidPhoneNumber(complete_phone_number);
}

// export function validatePhoneNumberOnly(phone_number) {
//     const regex = /^\d{8}$/;
//     return regex.test(phone_number);
// }


export async function validatePhoneNumberForSubscription(country_code, phone_number) {
    try {
        let complete_phone_number = country_code + phone_number;
        if (isValidPhoneNumber(complete_phone_number)) {
            let response = await checkAvailabilityValidityDataCall("phone_number", phone_number);
            return !response.hasSubscription;
        } else {
            return false;
        }
    } catch (error) {
        // Handle the error here
        console.error('An error occurred during phone number validation:', error);
        return false;
    }
}

export async function validatePhoneNumberAndSendCode(country_code, phone_number) {
    try {
        let complete_phone_number = country_code + phone_number;
        if (isValidPhoneNumber(complete_phone_number)) {
            let response = await checkAvailabilityValidityDataCall("phone_number", complete_phone_number);
            if (response.hasSubscription) {
                return false;
            } else {
                let data = { phone_number, country_code };
                let response2 = await verifyPhoneNumberSendCall(data);
                return response2.success;
            }
        } else {
            return false;
        }
    } catch (error) {
        // Handle the error here
        console.error('An error occurred during phone number validation:', error);
        return false;
    }
}



export async function validateVerificationCode(country_code, phone_number, code) {
    try {
        let complete_phone_number = country_code + phone_number;
        if (isValidPhoneNumber(complete_phone_number)) {
            let data = { phone_number, country_code, code };
            let response = await verifyPhoneNumberCheckNoLoginCall(data);
            return response.success;
        } else {
            return false;
        }
    } catch (error) {
        // Handle the error here
        console.error('An error occurred during verification:', error);
        return false;
    }
}


export function validatePostalCode(postal_code) {
    const minLength = 6;
    const pattern = /^[0-9]{4}[A-Za-z]{2}$/;

    return (
        hasAValue(postal_code) &&
        postal_code.length === minLength &&
        !/\s/.test(postal_code) &&
        pattern.test(postal_code)
    );
}

export function validateHouseNumber(house_number) {
    const minLength = 0;
    return hasAValue(house_number) &&  house_number.length > minLength;
}


export function validateArray(array) {
    const minLength = 1; // Minimum length required for the array

    return Array.isArray(array) && array.length >= minLength;
}
