import React, {useRef} from 'react';
import Slider from "react-slick";


const settings = {
    speed: 3000,
    dots: false,
    arrows: false,
    slidesToShow: 9,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    rows: 3,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        }
    ]

};
export default function SliderPartners({data}) {
    const sliderRef = useRef(null);
    return (
        <div className="col-12 p-0 border-bottom border-top m-0">
            {/* App Screenshot Slider Area */}
            <Slider {...settings} ref={sliderRef}>
                {/* Single Screenshot Item */}
                {data.map((item, idx) => {
                    return (
                        <div className="d-flex justify-content-center p-2 border-left border-bottom">
                            {/* Work Icon */}
                            <div className={"d-flex partners-logos-section align-items-center justify-content-center"}>
                                <img src={item.image} alt="" className={"partners-logos m-2"}/>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
}
