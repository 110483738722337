import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import {Modal} from "react-bootstrap";
import KnaekPrimaryButton from "./KnaekPrimaryButton";
import {hasAValue} from "../../uitls/SharedFunctions";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords, customStyles, internationalDialingCodes} from "../../config/Constants";



export default function ApplyJobComponent({job ,phone_number, setPhone_number, applyVacancy, setName, name,
                                              setCountry_code, setShowModal, showModal, agreeToTerms, setAgreeToTerms,
                                              country_code, agreeToUpdates, setAgreeToUpdates, setEmail, email}) {

    const [errorMessage, setErrorMessage] = useState(undefined)



    const initString = {
        title: stringsTranslate(appWords.apply_component_title),
        name_label:  stringsTranslate(appWords.name_label),
        name_placeholder:  stringsTranslate(appWords.name_placeholder),
        phone_number_label:  stringsTranslate(appWords.phone_number_label),
        phone_number_placeholder:  stringsTranslate(appWords.phone_number_placeholder),
        country_code_label:  stringsTranslate(appWords.country_code_label),
        country_code_placeholder:  stringsTranslate(appWords.country_code_placeholder),
        postal_code_label:  stringsTranslate(appWords.postal_code_label),
        postal_code_placeholder:  stringsTranslate(appWords.postal_code_placeholder),
        house_number_label:  stringsTranslate(appWords.house_number_label),
        house_number_placeholder:  stringsTranslate(appWords.house_number_placeholder),
        email_placeholder:  stringsTranslate(appWords.email_placeholder),
        email_label:  stringsTranslate(appWords.email_label),
        applyButton: stringsTranslate(appWords.apply_button),
        agree_jopp_terms_1: stringsTranslate(appWords.agree_jopp_terms_1),
        agree_jopp_terms_2: stringsTranslate(appWords.agree_jopp_terms_2),
        agree_jopp_terms_3: stringsTranslate(appWords.agree_jopp_terms_3),
        agree_jopp_terms_4: stringsTranslate(appWords.agree_jopp_terms_4),
    }

    return (
        <Modal className="modal fade radius-15 mt-4" size={"lg"} show={showModal}
               onHide={() => setShowModal(false)}>
            <div className="radius-15" role="document">
                <div className="radius-15">
                    <div className={"radius-15 pt-3 pb-3 bg-light"}>
                        {/*<div className="modal-header">*/}
                        {/* <h4 className="modal-title fs-20">{initString.header} </h4>*/}
                        {/*</div>*/}
                        <div className="modal-body  radius-15">
                            <div className="card border-top border-0">
                                <div className="card-header bg-light border-0 d-flex justify-content-center">
                                    <h1 className="card-title">{initString.title} {hasAValue(job)? job.jobTitle: ""}</h1>
                                </div>

                                <div className="card-body bg-light pb-0">
                                    <ul className="list-group list-group-flush">

                                        <li className="list-group-item radius-15 login-fields-div border-0 my-2">
                                            <label className={""}>{initString.name_label}</label>
                                            <input type="text"
                                                   className="form-control bg-transparent shadow-none"
                                                   defaultValue={name}
                                                   placeholder={initString.name_placeholder}
                                                   onChange={(val)=> setName(val.target.value)}
                                            />
                                        </li>

                                        <li className="list-group-item radius-15 login-fields-div border-0 my-0">
                                            <label className={"mb-0 pb-0"}>{initString.country_code_label}</label>
                                            <Select
                                                className="basic-single m-0 p-0"
                                                classNamePrefix="select"
                                                defaultValue={internationalDialingCodes.countryCodes[0]}
                                                onChange={(val)=> setCountry_code(val.value)}
                                                isClearable={false}
                                                isSearchable={true}
                                                name="color"
                                                styles={customStyles} // Pass the custom styles
                                                options={internationalDialingCodes.countryCodes}
                                            />
                                        </li>

                                        <li className="list-group-item radius-15 login-fields-div border-0 my-2">
                                            <label className={""}>{initString.phone_number_label}</label>
                                            <input type="text"
                                                   className="form-control bg-transparent shadow-none"
                                                   defaultValue={phone_number}
                                                   value={phone_number}
                                                   placeholder={initString.phone_number_placeholder}
                                                   onChange={(val) => {
                                                       const formattedPhoneNumber = val.target.value.replace(/^0/, '');
                                                       setPhone_number(formattedPhoneNumber)
                                                   }}
                                            />
                                        </li>
                                        <li className="list-group-item radius-15 login-fields-div border-0 my-2">
                                            <label className={""}>{initString.email_label}</label>
                                            <input type="email"
                                                   className="form-control bg-transparent shadow-none"
                                                   defaultValue={email}
                                                   placeholder={initString.email_placeholder}
                                                   onChange={(val)=> setEmail(val.target.value)}
                                            />
                                        </li>

                                        <li className=" radius-15 border-0 mt-2">
                                                <div className={"ml-1 d-flex justify-content-start align-items-start"}>
                                                    <label className={" pl-2 cursor-pointer order-page-checkbox-text"}>
                                                        {initString.agree_jopp_terms_1}
                                                        {initString.agree_jopp_terms_2}
                                                    </label>
                                                </div>
                                        </li>

                                        <li className=" radius-15 border-0 mb-1">
                                            <div className={"ml-3 d-flex justify-content-start align-items-start"}>
                                                <input
                                                    className={"check-box-order-page rounded-circle cursor-pointer"}
                                                    type="radio"
                                                    checked={agreeToUpdates}
                                                    name="agreeToUpdates"
                                                    onClick={val => setAgreeToUpdates(!agreeToUpdates)}
                                                />
                                                <label className={" pl-2 cursor-pointer order-page-checkbox-text"}   onClick={val => setAgreeToUpdates(!agreeToUpdates)} >
                                                    <label className={" pl-2 cursor-pointer order-page-checkbox-text"}>
                                                        {initString.agree_jopp_terms_3}
                                                    </label>

                                                </label>
                                            </div>
                                        </li>

                                        <li className=" radius-15 border-0 mb-1">
                                            <div className={"ml-3 d-flex justify-content-start align-items-start"}>
                                                <input
                                                    className={"check-box-order-page rounded-circle cursor-pointer"}
                                                    type="radio"
                                                    checked={agreeToTerms}
                                                    name="agreeToTerms"
                                                    onClick={val => setAgreeToTerms(!agreeToTerms)}
                                                />
                                                <label className={" pl-2 cursor-pointer order-page-checkbox-text"}   onClick={val => {
                                                    setAgreeToTerms(!agreeToTerms)}} >
                                                    <label className={" pl-2 cursor-pointer order-page-checkbox-text"} dangerouslySetInnerHTML={{__html: initString.agree_jopp_terms_4}}/>
                                                </label>
                                            </div>
                                        </li>

                                        {hasAValue(errorMessage) && <label className={"my-3 ml-2 text-danger"}>{errorMessage}</label>}

                                        <li className="bg-light my-3 d-flex justify-content-between align-items-center">
                                            <KnaekPrimaryButton title={initString.applyButton}
                                                                className={"border-0"}
                                                                onClick={()=> applyVacancy()}
                                            />
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    );
}
