import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import SnipTechFilterCategoryOnlineDiscounts from "../../AppCustomizedComponent/SniptechFilterCategoryOnlineDiscounts";
import SnipTechListItemsOnlineDiscounts from "../../AppCustomizedComponent/SniptechListItemsOnlineDiscounts";


export default function SnipTechOnlineDiscountsPart() {
    const initStrings = {
        section1heading: stringsTranslate(appWords.online_discounts),
        section1CounterText: stringsTranslate(appWords.results),
        sectionSearchHeading: stringsTranslate(appWords.results),
        sectionFilterHeading: stringsTranslate(appWords.college_town),
        searchPlaceholder: stringsTranslate(appWords.find_an_online_discount),
    }

    const snipTechStoresCountRS = useSelector(state => state.snipTechStoresCountRS);
    const [filters, setFilters] = useState({});
    const [searchText, setSearchText] = useState('');

    const delay = 500;
    let debounceTimer;
    const handleInputChange = (e) => {
        const value = e.target.value;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            setSearchText(value);
        }, delay);
    };

    return (
        <section className="section align-items-start justify-content-start mt-5">
            <div className="container-fluid mb-5">
                <div className="row">

                    {/*Element 1*/}
                    <div className="col-lg-2 col-12 text-left border-top border-right d-flex align-items-center">
                        <p className="text-primary">{snipTechStoresCountRS + " " + initStrings.section1CounterText}</p>
                    </div>

                    {/*Element Search balk*/}
                    <div className="col-lg-10 col-12 text-left border-top d-flex align-items-center">
                        <span className="input-group-text w-100 h-100 border-0 bg-white">
                            <i className="fa fa-search text-primary"/>
                            <input type="text" className="form-control search-input-store-discounts h6 h-100 mt-2"
                                   placeholder={initStrings.searchPlaceholder}
                                   onChange={handleInputChange}/>
                        </span>
                    </div>

                    <div className="col-lg-2 col-12 text-left border-top border-right border-bottom pb-3 pl-3 pr-3">

                        <SnipTechFilterCategoryOnlineDiscounts
                            initStrings={initStrings}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </div>

                    {/*Element List items*/}
                    <SnipTechListItemsOnlineDiscounts filters={filters} searchQuery={searchText}/>
                </div>
            </div>
        </section>
    );
}
