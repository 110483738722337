import React from "react";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import { useHandleLinkClick } from "../../../hooks/useHandleLinkClick";



export default function KnaekIDUsers() {
    const initStrings = {
        plyImage: "/assets/images/studentkorting/KnaekUsers.png",
        daily_users: stringsTranslate(appWords.daily_users),
        unique_users: stringsTranslate(appWords.unique_users),
        apps_launched: stringsTranslate(appWords.apps_launched),
        impressions_per_year: stringsTranslate(appWords.impressions_per_year),
        students_reached: stringsTranslate(appWords.students_reached),
        views_per_person: stringsTranslate(appWords.views_per_person),
        discount_locations: stringsTranslate(appWords.discount_locations),
        educational_institutions: stringsTranslate(appWords.educational_institutions),
        promotion_partners: stringsTranslate(appWords.promotion_partners),
        student_associations: stringsTranslate(appWords.student_associations),
    }
    const { width } = useWindowDimensions()
    const handleLinkClick = useHandleLinkClick();

    
    return (
        <section className="section bg-gray align-items-start justify-content-center mt-5 py-5">
            <div className="container px-5">

                    {/* First Row */}
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 text-center">
                            <h1 style={{ fontSize: '5.8rem', fontWeight: 'bold' }}>86.000</h1>
                            <p style={{fontWeight: '500', marginTop:'-1rem'}}>{initStrings.unique_users}</p>
                        </div>

                        <div className="mt-4 col-12 col-lg-7 col-xl-8 col-md-6 col-sm-12 half-circle mx-auto text-start" style={{position:'relative'}}>
                            <div className="d-flex align-items-start mb-3">
                                <div className='d-flex justify-content-end mr-1 mr-sm-2 pr-1 pr-sm-2' style={width > 767 ? {backgroundColor:'#D44F9E', borderRadius:'4px 0 0 4px', minWidth:'42%'} : {backgroundColor:'#D44F9E', borderRadius:'4px 0 0 4px', minWidth:'32%'}}> 
                                    <p style={{ color: '#FFF', fontSize: '1rem', fontWeight: '600'}}>4.000</p>
                                </div>
                                <p style={{fontWeight: '500'}}>{initStrings.daily_users}</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <div className='d-flex justify-content-end mr-1 mr-sm-2 pr-1 pr-sm-2' style={width > 767 ? {backgroundColor:'#D44F9E', borderRadius:'4px 0 0 4px', minWidth:'32%'} : {backgroundColor:'#D44F9E', borderRadius:'4px 0 0 4px', minWidth:'30%'}}> 
                                    <p style={{ color: '#FFF', fontSize: '1rem', fontWeight: '600' }}>566.000</p>
                                </div>
                                <p style={{fontWeight: '500'}}>{initStrings.apps_launched}</p>
                            </div>
                        </div>
                    </div>

                    {/* Second Row */}
                    <div className="row mb-4 pb-4 text-sm-center text-center" style={{borderBottom: '8px solid #E4E5E8'}}>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 mt-2 mb-2">
                            <i className="fab fa-instagram mr-5" style={{ fontSize: '4.5rem', color: '#D44F9E' }}/>
                            <i className="fab fa-facebook-f" style={{ fontSize: '4.5rem', color: '#D44F9E' }}/>
                        </div>
                        <div className="d-flex flex-column justify-content-around col-lg-3 col-md-4 col-sm-4 col-12">
                            <h2 style={{ fontSize: '4rem', fontWeight: '600' }}>15M</h2>
                            <p style={{fontWeight: '500'}}>{initStrings.impressions_per_year}</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                            <h2 style={{ fontSize: '4rem', fontWeight: '600' }}>1.3M</h2>
                            <p style={{fontWeight: '500'}}>{initStrings.students_reached}</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                            <h2 style={{ fontSize: '4rem', fontWeight: '600' }}>12x</h2>
                            <p style={{fontWeight: '500'}}>{initStrings.views_per_person}</p>
                        </div>
                    </div>

                    {/* Third Row */}
                    <div className="row text-center">
                        <div className="d-flex flex-column justify-content-center col-12 col-md-4">
                            <h2 style={{ fontSize: '4.5rem', fontWeight: '600' }} >1500+</h2>
                            <p style={{fontWeight: '500'}}>{initStrings.discount_locations}</p>
                        </div>

                        <div className="d-flex flex-column col-12 col-md-8" style={width > 767 ? {borderLeft: '8px solid #E4E5E8'} : {borderTop: '8px solid #E4E5E8', marginTop:'10px', paddingTop:'10px'}}>
                            <p style={{ fontSize: '1.2rem', fontWeight: '600' }}>{initStrings.promotion_partners}</p>
                            <div className="row">
                                <div className="col-6">
                                    <h2 style={{ fontSize: '3rem', fontWeight: '600' }}>200+</h2>
                                    <p style={{fontWeight: '500'}}>{initStrings.educational_institutions}</p>
                                </div>
                                <div className="col-6">
                                    <h2 style={{ fontSize: '3rem', fontWeight: '600' }}>1400+</h2>
                                    <p style={{fontWeight: '500'}}>{initStrings.student_associations}</p>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
        </section>
    );
}
