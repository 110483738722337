import React from 'react';
import SnipTechBannerSectionOnlineDiscounts
    from "../../components/AppSections/SniptechOnlineDiscountsPageSections/SniptechBannerSectionOnlineDiscounts";
import SnipTechOnlineDiscountsPart
    from "../../components/AppSections/SniptechOnlineDiscountsPageSections/SniptechOnlineDiscountsPart";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";


export default function SnipTechOnlineDiscountsPage() {
    const online_discounts_cm = stringsTranslate(appWords.online_discounts_cm)
    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {online_discounts_cm}</title>
            </Helmet>
            <div className="main">
                <SnipTechBannerSectionOnlineDiscounts/>
                <SnipTechOnlineDiscountsPart/>
            </div>
        </div>
    );
}
