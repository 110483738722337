import React from 'react';
import AboutUsIntroSection from "../../components/AppSections/AboutUs/AboutUsIntroSection";
import FooterSection from "../../components/FooterSection/Footer";
import {Helmet} from "react-helmet";
import {appWords} from "../../config/Constants";
import {stringsTranslate} from "../../locales/CustomTranslater";

export default function KnaekAboutUs() {


    const about_us_heading = stringsTranslate(appWords.about_us_heading)

    return (
        <div className="homepage-4 pt-5 mt-5">
            <Helmet>
                <title>Knaek - {about_us_heading}</title>
            </Helmet>
            <div className="main">
                <AboutUsIntroSection />
                <FooterSection/>
            </div>
        </div>
    );
}
