    import Swal from "sweetalert2";
import {stringsTranslate} from "../locales/CustomTranslater";
import {appColors, appWords} from "../config/Constants";
import {reduxStore} from "../redux/reduxStore";
import {updateShowAuthModalRS} from "../redux/reduxActions/reduxStoreActions";


export function afterLoginAlertFunction() {
    Swal.fire({
        title: stringsTranslate(appWords.alert_go_to_profile_title),
        text: stringsTranslate(appWords.alert_go_to_profile_subtitle),
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: appColors.primary,
        cancelButtonColor: appColors.primary,
        confirmButtonText: (stringsTranslate(appWords.no)).toString().toUpperCase(),
        cancelButtonText: (stringsTranslate(appWords.to_profile_button)).toString().toUpperCase(),
    }).then((result) => {
        if (result.isConfirmed) {
            // reduxStore.dispatch(updateShowAuthModalRS(false))
        }else {
            // reduxStore.dispatch(updateShowAuthModalRS(false))
            window.location.href= "/account"
        }
    })
}


export function validationErrorAlertFunction(errorMessage) {
    Swal.fire({
        title: stringsTranslate(appWords.not_valid_data),
        text: errorMessage,
        icon: 'warning',
        focusCancel: false,
        focusConfirm: false,
        focusDeny: false,
        inputAutoFocus: false,
        returnFocus: false,
        confirmButtonColor: appColors.primary,
        cancelButtonColor: appColors.primary,
        confirmButtonText: (stringsTranslate(appWords.ok)).toString().toUpperCase(),
    }).then((result) => {
    })
}



export function afterLogoutAlertFunction() {
    Swal.fire({
        title: stringsTranslate(appWords.alert_you_are_logged_out),
        icon: 'success',
        confirmButtonColor: appColors.primary,
        cancelButtonColor: appColors.primary,
        showCancelButton: false,
    }).then((result) => {
            // window.location.href= "/"
    })
}


export function loadingAlertFunction() {
    Swal.fire({
        title: stringsTranslate(appWords.alert_loading_text),
        icon: 'loading',
        confirmButtonColor: appColors.primary,
        cancelButtonColor: appColors.primary,
        showCancelButton: false,
        didOpen: () => {
            Swal.showLoading()
        },
    }).then((result) => {
    })
}

export function closeAlertFunction() {
    Swal.close()
}

export function tokenErrorAlertFunction( title, errorMessage) {
    Swal.fire({
        title: title,
        text: errorMessage,
        icon: 'warning',
        focusCancel: false,
        focusConfirm: false,
        focusDeny: false,
        inputAutoFocus: false,
        returnFocus: false,
        confirmButtonColor: appColors.primary,
        cancelButtonColor: appColors.primary,
        confirmButtonText: (stringsTranslate(appWords.ok)).toString().toUpperCase(),
    }).then((result) => {
    })
}


export function successAlertFunction(title, message, callbackFunction = null) {
    Swal.fire({
        title: title,
        text: message,
        icon: 'success',
        focusCancel: false,
        focusConfirm: false,
        focusDeny: false,
        inputAutoFocus: false,
        returnFocus: false,
        confirmButtonColor: appColors.primary,
        cancelButtonColor: appColors.primary,
        confirmButtonText: (stringsTranslate(appWords.ok)).toString().toUpperCase(),
    }).then((result) => {
        callbackFunction();
    })
}

export function failedAlertFunction(title, message) {
    Swal.fire({
        title: title,
        text: message,
        icon: 'error',
        focusCancel: false,
        focusConfirm: false,
        focusDeny: false,
        inputAutoFocus: false,
        returnFocus: false,
        confirmButtonColor: appColors.knaek_danger,
        cancelButtonColor: appColors.knaek_danger,
        confirmButtonText: (stringsTranslate(appWords.back)).toString().toUpperCase(),
    }).then((result) => {
    })
}

    export function afterPayoutRequestAlertFunction(title, message) {
        Swal.fire({
            title: title,
            text: message,
            icon: 'warning',
            focusCancel: false,
            focusConfirm: false,
            focusDeny: false,
            inputAutoFocus: false,
            returnFocus: false,
            confirmButtonColor: appColors.knaek_danger,
            cancelButtonColor: appColors.knaek_danger,
            confirmButtonText: (stringsTranslate(appWords.back)).toString().toUpperCase(),
        }).then((result) => {
        })
    }


export function waitAlertFunction(title, message) {
        let timerInterval
        Swal.fire({
            title: title || 'Auto close alert!',
            timer: 5000,
            icon: 'info',
            timerProgressBar: true,
            showConfirmButton: false,
            // didOpen: () => {
            //     Swal.showLoading()
            //     const b = Swal.getHtmlContainer().querySelector('b')
            //     timerInterval = setInterval(() => {
            //         b.textContent = Math.ceil(Swal.getTimerLeft() / 1000)
            //     }, 100)
            // },
            willClose: () => {
                clearInterval(timerInterval)
            }
        })
    }
