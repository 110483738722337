import React, {useState} from "react";
import {appWords} from "../../../config/Constants";
import {stringsTranslate} from "../../../locales/CustomTranslater";


export default function AboutUsDetailsSection() {
    const [showElement1, setShowElement1] = useState(false)
    const [showElement2, setShowElement2] = useState(false)
    const [showElement3, setShowElement3] = useState(false)

    const initStrings = {
        heading1: stringsTranslate(appWords.about_us_details_heading_1),
        heading2: stringsTranslate(appWords.about_us_details_heading_2),
        heading3: stringsTranslate(appWords.about_us_details_heading_3),
        playImage1: "../assets/images/Knaek-hoofdkantoor.jpg",
        playImage2: "../assets/images/Knaek-stadsmanager.jpg",
        playImage3: "../assets/images/Knaek-stage-baan.jpg",

        conquer_the_world_of_partnerships_and_grow_with_us: stringsTranslate(appWords.conquer_the_world_of_partnerships_and_grow_with_us),
        you_will_have_the_opportunity_to_manage_and_attrac: stringsTranslate(appWords.you_will_have_the_opportunity_to_manage_and_attrac),
        apply_now_button: stringsTranslate(appWords.apply_now_button),
        become_the_city_manager_of_your_student_city: stringsTranslate(appWords.become_the_city_manager_of_your_student_city),
        as_city_manager__you_will_be_responsible_for_keepi: stringsTranslate(appWords.as_city_manager__you_will_be_responsible_for_keepi),
        would_you_like_to_help_knaek_represent_your_colleg: stringsTranslate(appWords.would_you_like_to_help_knaek_represent_your_colleg),
        as_a_coach__you_are_responsible_for_the_smooth_run: stringsTranslate(appWords.as_a_coach__you_are_responsible_for_the_smooth_run),

    }

    return (
        <div id="screenshots" className="section mt-2 partner-section w-100 mb-5">
            <div className="container-fluid ">
                <div className="row ">
                    {/* Section Heading */}
                    <div className={"col-lg-4 text-center  my-3"}>
                        <div className={"col-12 card shadow w-100 radius-15  cursor-pointer"}>
                            <div className={"card-body"}  onClick={()=>setShowElement1(!showElement1)}>
                                <div className={"col-12 text-center"} >
                                    <h4>{initStrings.heading1}</h4>
                                </div>
                                <img src={initStrings.playImage1} alt={""} />
                                {showElement1 && <div className={"col-12 text-left text-justify"}>
                                    <br/>
                                    <p>{initStrings.conquer_the_world_of_partnerships_and_grow_with_us}</p>
                                    <br/>
                                    <p>{initStrings.you_will_have_the_opportunity_to_manage_and_attrac}</p>
                                    <br/>
                                </div>}

                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-4 text-center  my-3"}>
                        <div className={"col-12 card shadow w-100 radius-15  cursor-pointer"}>
                            <div className={"card-body"}  onClick={()=>setShowElement2(!showElement2)}>
                                <div className={"col-12 text-center"}  >
                                    <h4>{initStrings.heading2}</h4>
                                </div>
                                <img src={initStrings.playImage2} alt={""}/>
                                {showElement2 && <div className={"col-12 text-left text-justify"}>
                                    <br/>
                                    <p>{initStrings.become_the_city_manager_of_your_student_city}</p>
                                    <br/>
                                    <p>{initStrings.as_city_manager__you_will_be_responsible_for_keepi}</p>
                                    <br/>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-4 text-center my-3"}>
                        <div className={"col-12 card shadow w-100 radius-15 cursor-pointer"}>
                            <div className={"card-body"}  onClick={()=>setShowElement3(!showElement3)}>
                                <div className={"col-12 text-center"} >
                                    <h4>{initStrings.heading3}</h4>
                                </div>
                                <img src={initStrings.playImage3} alt={""} />
                                {showElement3 && <div className={"col-12 text-left text-justify"}>
                                    <br/>
                                    <p>{initStrings.would_you_like_to_help_knaek_represent_your_colleg}</p>
                                    <br/>
                                    <p>{initStrings.as_a_coach__you_are_responsible_for_the_smooth_run}</p>
                                    <br/>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}
