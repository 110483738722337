import React, { forwardRef } from 'react';

const KnaekPrimaryButton = forwardRef(({ className, title, ...props }, ref) => {
    return (
        <button ref={ref} className={`btn btn-knaek-primary radius-50 shadow p-4 px-5 ${className}`} {...props}>
            {title}
        </button>
    );
});

export default KnaekPrimaryButton;
