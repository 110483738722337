import axios from "axios";
import {
  updateAuthDataNEWRS,
  updateAuthDataRS,
} from "../redux/reduxActions/reduxStoreActions";
import { reduxStore } from "../redux/reduxStore";
import { generateSignature, hasAValue } from "../uitls/SharedFunctions";
import { appUrls } from "../config/Constants";

export const handleTokenRefresh = async (error, method, route, dataSent) => {
  let refreshToken = "";
  const stateRedux = reduxStore.getState();
  const authDataRS = stateRedux.authDataNEWRS;

  if (hasAValue(authDataRS)) {
    refreshToken = authDataRS.refreshToken;
  }

  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    try {
      const { signature, timestamp } = generateSignature({ refreshToken });

      const { data } = await axios.post(
        appUrls.backend_api + "v4/auth/refresh",
        { refreshToken: refreshToken },
        {
          headers: {
            Authorization: "Bearer " + authDataRS.accessToken,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            signature: signature,
            timestamp: timestamp,
          },
        }
      );

      console.log("v4/auth/refresh ", data);

      // Dispatch the new access token action
      reduxStore.dispatch(updateAuthDataNEWRS(data));

      const { signature: retrySignature, timestamp: retryTimestamp } =
        generateSignature(dataSent);

      // Retry the original request with the new access token
      const retryResponse = await axios(route, {
        method: method,
        data: dataSent,
        headers: {
          Authorization: "Bearer " + data.accessToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          signature: retrySignature,
          timestamp: retryTimestamp,
        },
      });

      return retryResponse.data;
    } catch (refreshError) {
      console.error("Refresh token failed:", refreshError);
      reduxStore.dispatch(updateAuthDataRS(null))
      throw refreshError;
    }
  } else {
    throw error;
  }
};