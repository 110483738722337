import ReactPixel from 'react-facebook-pixel';

export const facebookTrackDownload = () =>{
    ReactPixel.track('Download');
}

export const facebookTrackCompleteRegistration = () =>{
    ReactPixel.track('CompleteRegistration');
}

