import TiktokPixel from 'tiktok-pixel';
import {generateUniqueCode} from './SharedFunctions'



const trackingOptions = {
    event_id: generateUniqueCode()
};

export const tiktokTrackDownload = async () => {
    TiktokPixel.track('Download', {}, trackingOptions);
}

export const tiktokTrackCompleteRegistration = async () =>{
    TiktokPixel.track('CompleteRegistration', {}, trackingOptions);
}
