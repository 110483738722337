import words from "../locales/words.json"
import countryCodes from "../jsonDB/countryCodesList.json"

export const appColors = {
    primary: '#d44f9e',
    knaek_linear:'#e23d9f',
    knaek_title: '#212c41',
    knaek_sub: '#6d7789',
    knaek_input: '#edeff1',
    knaek_lightest: '#f9e5f0',
    knaek_blue: '#5681d3',
    knaek_danger: '#d44f77',
    knaek_alert: '#fbd050',
    secondaryAlt: '#090808',
    border: '#000000',
    inactive: '#c7ced3',
    baseBackground: '#F6F6F7',
    darkBackground: 'rgba(2,56,3,0.4)',
    white: '#FFFFFF',
    light: '#A8B3BA',
    black: '#090808',
    knaek_valid: '#3eba77',
    red: '#FF4500',
    transparent: 'rgba(1,214,132,0.2)',
    gray: '#505050',
}


export const appLocalStoargeValues = {
    userData: 'userData',
    loggedIn: 'loggedIn',
    language: 'language',
}

export const appUrls = {
    backend_api: process.env.REACT_APP_BACKEND_API, //production
    image_url: 'https://img.knaek.com/', // production
    jobs_image_url: 'https://jopp-api.it-plus24-hosting.com/public/uploads/', // production
    map_url: 'https://goo.gl/maps/HWMYj8s5PfcgEn5Y6/', // Map link
}

export const appRoutes = {
    home: '/',
    features: '/features',
}

export const appLanguage = {
    nl: 'nl',
    en: 'en',
    be: 'be',
}

export const appWords = words


export const internationalDialingCodes = countryCodes


export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        zIndex: '99999 !important', // Replace 9999 with your desired z-index value
        border: 'none', // Remove the border
        boxShadow: 'none', // Remove the box shadow
        '&:hover': {
            border: 'none', // Remove border on hover
        },
        '&:focus': {
            outline: 'none', // Remove outline on focus
            boxShadow: 'none', // Remove box shadow on focus
        },
        backgroundColor: 'transparent',
        margin: 0,
        padding: 0,
        "& .react-select__dropdown-indicator": {
            color: appColors.primary, // Replace "red" with your desired color
        },
    }),
};



export const joppStockImagesOthers = {
    1: "assets/images/Jopp/other/1.png",
    2:"assets/images/Jopp/other/2.png",
    3: "assets/images/Jopp/other/3.png",
    4: "assets/images/Jopp/other/4.png",
    5: "assets/images/Jopp/other/5.png",
    6: "assets/images/Jopp/other/6.png",
    7: "assets/images/Jopp/other/7.png",
    8: "assets/images/Jopp/other/8.png",
    9:"assets/images/Jopp/other/9.png",
    10: "assets/images/Jopp/other/10.png",
}

export const joppStockImagesAdministrative = {
    1: ('assets/images/Jopp/administrative/1.png'),
    2:  ('assets/images/Jopp/administrative/2.png'),
    3: ('assets/images/Jopp/administrative/3.png'),
    4: ( 'assets/images/Jopp/administrative/4.png'),
    5: ('assets/images/Jopp/administrative/5.png'),
    6: ('assets/images/Jopp/administrative/6.png'),
    7: ('assets/images/Jopp/administrative/7.png'),
    8: ('assets/images/Jopp/administrative/8.png'),
    9: ('assets/images/Jopp/administrative/9.png'),
    10: ('assets/images/Jopp/administrative/10.png')
}

export const joppStockImagesCateringIndustry = {
    1: ('assets/images/Jopp/horeca/1.png'),
    2:  ('assets/images/Jopp/horeca/2.png'),
    3: ('assets/images/Jopp/horeca/3.png'),
    4: ( 'assets/images/Jopp/horeca/4.png'),
    5: ('assets/images/Jopp/horeca/5.png'),
    6: ('assets/images/Jopp/horeca/6.png'),
    7: ('assets/images/Jopp/horeca/7.png'),
    8: ('assets/images/Jopp/horeca/8.png'),
    9: ('assets/images/Jopp/horeca/9.png'),
    10: ('assets/images/Jopp/horeca/10.png')
}

export const joppStockImagesDelivery = {
    1: ('assets/images/Jopp/delivery/1.png'),
    2:  ('assets/images/Jopp/delivery/2.png'),
    3: ('assets/images/Jopp/delivery/3.png'),
    4: ( 'assets/images/Jopp/delivery/4.png'),
    5: ('assets/images/Jopp/delivery/5.png'),
    6: ('assets/images/Jopp/delivery/6.png'),
    7: ('assets/images/Jopp/delivery/7.png'),
    8: ('assets/images/Jopp/delivery/8.png'),
    9: ('assets/images/Jopp/delivery/9.png'),
    10: ('assets/images/Jopp/delivery/10.png')
}

export const joppStockImagesMarketingAndSales = {
    1: ('assets/images/Jopp/marketingAndSales/1.png'),
    2:  ('assets/images/Jopp/marketingAndSales/2.png'),
    3: ('assets/images/Jopp/marketingAndSales/3.png'),
    4: ( 'assets/images/Jopp/marketingAndSales/4.png'),
    5: ('assets/images/Jopp/marketingAndSales/5.png'),
    6: ('assets/images/Jopp/marketingAndSales/6.png'),
    7: ('assets/images/Jopp/marketingAndSales/7.png'),
    8: ('assets/images/Jopp/marketingAndSales/8.png'),
    9: ('assets/images/Jopp/marketingAndSales/9.png'),
    10: ('assets/images/Jopp/marketingAndSales/10.png')
}

export const joppStockImagesRetail = {
    1: ('assets/images/Jopp/retail/1.png'),
    2:  ('assets/images/Jopp/retail/2.png'),
    3: ('assets/images/Jopp/retail/3.png'),
    4: ( 'assets/images/Jopp/retail/4.png'),
    5: ('assets/images/Jopp/retail/5.png'),
    6: ('assets/images/Jopp/retail/6.png'),
    7: ('assets/images/Jopp/retail/7.png'),
    8: ('assets/images/Jopp/retail/8.png'),
    9: ('assets/images/Jopp/retail/9.png'),
    10: ('assets/images/Jopp/retail/10.png')
}

export const joppStockImagesCustomerService = {
    1: ('assets/images/Jopp/customerService/1.png'),
    2:  ('assets/images/Jopp/customerService/2.png'),
    3: ('assets/images/Jopp/customerService/3.png'),
    4: ( 'assets/images/Jopp/customerService/4.png'),
    5: ('assets/images/Jopp/customerService/5.png'),
    6: ('assets/images/Jopp/customerService/6.png'),
    7: ('assets/images/Jopp/customerService/7.png'),
    8: ('assets/images/Jopp/customerService/8.png'),
    9: ('assets/images/Jopp/customerService/9.png'),
    10: ('assets/images/Jopp/customerService/10.png')
}
export const joppStockImagesLogistics = {
    1: ('assets/images/Jopp/logistics/1.png'),
    2:  ('assets/images/Jopp/logistics/2.png'),
    3: ('assets/images/Jopp/logistics/3.png'),
    4: ( 'assets/images/Jopp/logistics/4.png'),
    5: ('assets/images/Jopp/logistics/5.png'),
    6: ('assets/images/Jopp/logistics/6.png'),
    7: ('assets/images/Jopp/logistics/7.png'),
    8: ('assets/images/Jopp/logistics/8.png'),
    9: ('assets/images/Jopp/logistics/9.png'),
    10: ('assets/images/Jopp/logistics/10.png')
}

