import {
  UPDATE_CITIES,
  UPDATE_JOBS,
  UPDATE_LOCATIONS,
  UPDATE_SHOW_AUTH_MODAL,
  UPDATE_STORE_DISCOUNTS,
  UPDATE_AUTH_DATA,
  UPDATE_LANGUAGE,
  UPDATE_ORDER_KNAEK_DATA,
  UPDATE_ONLINE_DISCOUNTS_SnipTech,
  UPDATE_SNIP_TECH_STORES_COUNT,
  UPDATE_AUTH_DATA_NEW,
  UPDATE_SHOW_LOGIN_LINK_MODAL,
  UPDATE_EMAIL_IN_MODAL,
} from "./reduxActions/reduxStoreActions";
import { combineReducers } from "redux";

const authDataRS = (val = null, action) => {
  switch (action.type) {
    case UPDATE_AUTH_DATA:
      return action.val;
    default:
      return val;
  }
};

const storeDiscountsRS = (list = [], action) => {
  switch (action.type) {
    case UPDATE_STORE_DISCOUNTS:
      return action.list;
    default:
      return list;
  }
};

const citiesRS = (list = [], action) => {
  switch (action.type) {
    case UPDATE_CITIES:
      return action.list;
    default:
      return list;
  }
};

const locationsRS = (list = [], action) => {
  switch (action.type) {
    case UPDATE_LOCATIONS:
      return action.list;
    default:
      return list;
  }
};

const jobsRS = (list = [], action) => {
  switch (action.type) {
    case UPDATE_JOBS:
      return action.list;
    default:
      return list;
  }
};

const showAuthModalRS = (val = false, action) => {
  switch (action.type) {
    case UPDATE_SHOW_AUTH_MODAL:
      return action.val;
    default:
      return val;
  }
};

const showLoginLinkModalRS = (val = false, action) => {
  switch (action.type) {
    case UPDATE_SHOW_LOGIN_LINK_MODAL:
      return action.val;
    default:
      return val;
  }
};

const emailInModalRS = (email = "", action) => {
  switch (action.type) {
    case UPDATE_EMAIL_IN_MODAL:
      return action.email;
    default:
      return email;
  }
};

const appLanguageRS = (val = null, action) => {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return action.val;
    default:
      return val;
  }
};

const orderKnaekDataRS = (val = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDER_KNAEK_DATA:
      return action.val;
    default:
      return val;
  }
};

const onlineDiscountsSnipTechRS = (list = [], action) => {
  switch (action.type) {
    case UPDATE_ONLINE_DISCOUNTS_SnipTech:
      return action.list;
    default:
      return list;
  }
};

const snipTechStoresCountRS = (val = 0, action) => {
  switch (action.type) {
    case UPDATE_SNIP_TECH_STORES_COUNT:
      return action.val;
    default:
      return val;
  }
};

const authDataNEWRS = (val = null, action) => {
  switch (action.type) {
    case UPDATE_AUTH_DATA_NEW:
      return action.val;
    default:
      return val;
  }
};

export default combineReducers({
  authDataRS,
  storeDiscountsRS,
  citiesRS,
  locationsRS,
  jobsRS,
  showAuthModalRS,
  appLanguageRS,
  orderKnaekDataRS,
  onlineDiscountsSnipTechRS,
  snipTechStoresCountRS,
  authDataNEWRS,
  showLoginLinkModalRS,
  emailInModalRS
});
