import React, {useEffect, useState} from 'react';
import FooterSection from '../../../components/FooterSection/Footer';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import JobDetailFirstSectionJobs
    from "../../../components/AppSections/JobsPageSections/JobDetailFirstSectionJobs";
import {Helmet} from "react-helmet";
import {appWords} from "../../../config/Constants";
import {stringsTranslate} from "../../../locales/CustomTranslater";

export default function JobInfo() {
    const {city_name, name, id} = useParams();
    const jobsRS = useSelector(state => state.jobsRS);


    const [job, setJob] = useState(undefined)

    useEffect(() => {
        let element = jobsRS.find(item => (item.id.toString().toLowerCase().includes(id.toString().toLowerCase())))
        setJob(element)
    }, [id])

    return (
        <div className="pt-5 mt-4">
                <Helmet>
                    <title>Knaek - {stringsTranslate(appWords.student_jobs_page_title)}</title>
                </Helmet>
                <JobDetailFirstSectionJobs job={job}/>
                {/*<SimilarJobs job={job}/>*/}
                <FooterSection/>
        </div>
    );
}
