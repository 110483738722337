import React, {useRef} from 'react';
import Slider from "react-slick";


const settings = {
    speed: 1000,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]

};
export default function SliderHowItWorks({data}) {
    const sliderRef = useRef(null);
    return (
        <div className="col-12">
            {/* App Screenshot Slider Area */}
            <Slider {...settings} ref={sliderRef}>
                {/* Single Screenshot Item */}
                {data.map((item, idx) => {
                    return (
                        <div key={idx} className="d-flex align-items-center justify-content-center text-center">
                            {/* Single Work */}
                            <div className="text-center w-75">
                                {/* Work Icon */}
                                <div className={"w-100 d-flex align-items-center justify-content-center"}>
                                    <img src={item.image} alt="" width={"250"}/>
                                </div>
                                <h4 className="text-knaek-primary">{item.title}</h4>
                                <h2 className="text-knaek-title">{item.subtitle}</h2>
                                <p className="text-knaek-sub">{item.content}</p>
                            </div>
                        </div>

                    );
                })}
            </Slider>
        </div>
    );
}
